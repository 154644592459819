import PropTypes from "prop-types";
import { useCallback } from "react";
import * as XLSX from "xlsx";

function Export(props) {
  const { data, fileName } = props;
  const formatObject = useCallback((obj) => {
    let processedValue = "";
    Object.keys(obj).forEach((key) => {
      processedValue += `${key}: ${obj[key]} `;
    });
    return processedValue;
  });
  const handleExportToExcel = useCallback(() => {
    const dataWithStrings = data.map((row) => {
      return Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          if (typeof value === "object" && value !== null) {
            return [key, formatObject(value)];
          }
          return [key, value];
        })
      );
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataWithStrings);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  });
  return (
    <button
      className="btn btn-sm btn-outline-success"
      type="button"
      onClick={handleExportToExcel}
    >
      <i className="bi bi-file-earmark-excel-fill" /> Export to Excel
    </button>
  );
}

Export.defaultProps = {
  fileName: "export",
};

Export.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  fileName: PropTypes.string,
};

export default Export;
