import PropTypes from "prop-types";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";

function BaseClaimCoupon({
  handleClaimCoupon,
  handleInputChange,
  state,
  showCouponForm,
  handleCoupon,
  isSubmitting,
  ClaimedCouponsComponent,
  couponFormLinkColor,
  claimCouponButtonColor,
}) {
  const couponForm = (
    <Form
      handleSubmit={handleClaimCoupon}
      button_label="Claim"
      button_icon="bi bi-cart-check"
      id="claim-coupon-form"
      isSubmitting={isSubmitting}
      padding="p-0"
      items_flow="horizontal"
      button_color={`outline-${claimCouponButtonColor}`}
      use_as="div"
    >
      <TextInput
        title="Coupon code"
        name="coupon_code"
        content={state.coupon_code}
        controlFunc={handleInputChange}
        required
      />
    </Form>
  );
  let couponComponent = (
    <div className="mb-3 d-flex justify-content-start align-items-center">
      <i className={`bi bi-gift text-${couponFormLinkColor}`} />
      <button
        className={`btn btn-link text-${couponFormLinkColor} text-start fs-5`}
        type="button"
        onClick={handleCoupon}
      >
        I have a coupon
      </button>
    </div>
  );
  if (showCouponForm) {
    couponComponent = (
      <div className="mb-3">
        <div className="col-12">{couponForm}</div>
      </div>
    );
  }
  return (
    <div className="mb-3">
      {couponComponent}
      <ClaimedCouponsComponent />
    </div>
  );
}

BaseClaimCoupon.propTypes = {
  handleClaimCoupon: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  showCouponForm: PropTypes.bool.isRequired,
  handleCoupon: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  ClaimedCouponsComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  couponFormLinkColor: PropTypes.string,
  claimCouponButtonColor: PropTypes.string,
};

BaseClaimCoupon.defaultProps = {
  couponFormLinkColor: "purple",
  claimCouponButtonColor: "purple",
};

export default BaseClaimCoupon;
