import PropTypes from "prop-types";

require("bootstrap-icons/font/bootstrap-icons.css");

export function IconLoading({ text }) {
  return (
    <div>
      <span className="spinner-border spinner-border-sm" role="status" /> {text}
    </div>
  );
}

export function Icon({ icon, additional_classes }) {
  return <i className={`bi-${icon} ${additional_classes}`} />;
}

IconLoading.propTypes = {
  text: PropTypes.string,
};

IconLoading.defaultProps = {
  text: null,
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  additional_classes: PropTypes.string,
};

Icon.defaultProps = {
  additional_classes: null,
};
