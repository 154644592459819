import { useLocation } from "react-router-dom";
import { MarketingApiActions } from "modules/marketing/ApiActions";

function FocusItems() {
  const marketingApiActions = new MarketingApiActions();
  const { focus_items } = marketingApiActions.getFocusItems();
  const location = useLocation();
  const { pathname } = location;
  const focus_items_to_append = focus_items.filter(
    (item) => item.app_path === pathname || item.app_path === "*",
  );
  // append focus items scripts to the body
  focus_items_to_append.forEach((item) => {
    const { script_url } = item;
    // check if script is already appended
    if (document.querySelector(`script[src="${script_url}"]`)) {
      return;
    }
    const script = document.createElement("script");
    script.src = script_url;
    script.async = true;
    document.body.appendChild(script);
  });
}

export default FocusItems;
