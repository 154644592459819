import { NavigateOptions, useNavigate } from "react-router-dom";

/**
 * Navigate to a URL.
 * @param url The URL to navigate to.
 * @param options Options for the navigation.
 */
export function navigateToUrl(url: string, options: NavigateOptions = {}) {
  const { replace = false, state = {}, target = "_self" } = options;

  if (target === "_self") {
    if (replace) {
      window.history.replaceState(state, "", url);
    } else {
      useNavigate(url);
    }
  } else {
    window.open(url, target);
  }
}
