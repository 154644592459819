import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

/**
 * Switch input component
 * @param {Object} props
 * @param {string} props.label
 */
function SwitchInput(props) {
  const id = uuidv4();
  const { label, handleInputChange, name, isChecked } = props;
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);
  const handleChange = useCallback(
    (event) => {
      setChecked(event.target.checked);
      handleInputChange(event);
    },
    [handleInputChange]
  );
  return (
    <div className="form-group">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={id}
          name={name}
          onChange={handleChange}
          checked={checked}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
}

SwitchInput.defaultProps = {
  handleInputChange: () => {
    // do nothing
  },
  isChecked: false,
};

SwitchInput.propTypes = {
  label: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
};

export default SwitchInput;
