import React, { useEffect } from "react";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import { CANNY_BOARD_TOKEN } from "modules/core/Constants";

function FeatureRequest() {
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          const f = d.getElementsByTagName(s)[0];
          const e = d.createElement(s);
          e.type = "text/javascript";
          e.async = !0;
          e.src = "https://canny.io/sdk.js";
          f.parentNode.insertBefore(e, f);
        }
      }
      if (typeof w.Canny !== "function") {
        const c = function () {
          c.q.push(arguments);
        };
        c.q = [];
        w.Canny = c;
        if (d.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, !1);
        }
      }
    })(window, document, "canny-jssdk", "script");

    window.Canny("render", {
      boardToken: CANNY_BOARD_TOKEN,
      basePath: null,
      ssoToken: null,
      theme: "light",
      onLoadCallback: () => setIsLoading(false),
    });
  }, []);
  let loadingComponent = null;
  if (isLoading) {
    loadingComponent = <NetworkActivityIndicator />;
  }
  return <div data-canny="">{loadingComponent}</div>;
}

export default FeatureRequest;
