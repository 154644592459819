import React from "react";
import { useParams } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";
import VirtualMachineActions from "modules/servers/components/VirtualMachineActions";
import VirtualMachineCard from "modules/servers/components/VirtualMachineCard";

function VirtualMachineDetails() {
  const { id: vm_id } = useParams();
  const serversApiActions = new ServersApiActions();
  const { vm, error, isLoading } = serversApiActions.getVirtualMachine(vm_id);
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <VirtualMachineCard
        key={vm.id}
        virtual_machine={vm}
        actions={<VirtualMachineActions subject_id={vm.id} row={vm} />}
        allowIPCopy
        isClickable={false}
      />
    </>
  );
}

export default VirtualMachineDetails;
