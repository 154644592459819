import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export default function DebouncedInput({
  value: initialValue,
  onChange,
  debounceTime,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const debouncedOnChange = debounce((newValue) => {
    onChange(newValue);
  }, debounceTime);

  useEffect(() => {
    debouncedOnChange(value);
    return () => debouncedOnChange.cancel();
  }, [value, debouncedOnChange]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

DebouncedInput.defaultProps = {
  value: "",
  debounceTime: 500,
};

DebouncedInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  debounceTime: PropTypes.number,
};
