import { init as initApm } from "@elastic/apm-rum";
import {
  ELASTIC_APM_SERVICE_NAME,
  ELASTIC_APM_URL,
} from "modules/core/Constants";

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: ELASTIC_APM_SERVICE_NAME,

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: ELASTIC_APM_URL,

  // Set service version (required for sourcemap feature)
  serviceVersion: "",
});

export default apm;
