import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

function CopyToClipboardButton(props) {
  const { text, noun } = props;
  const onClickHandler = useCallback(() => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success(`Copied ${noun}  to clipboard`);
    });
  }, [text, noun]);
  return (
    <button
      type="button"
      className="btn btn-link btn-sm p-0"
      onClick={onClickHandler}
    >
      <i className="bi bi-clipboard" />
    </button>
  );
}

CopyToClipboardButton.defaultProps = {
  text: "",
};

CopyToClipboardButton.propTypes = {
  text: PropTypes.string,
  noun: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
