import PropTypes from "prop-types";
import React from "react";
import SectionTitle from "modules/base/components/SectionTitle";

function SectionCard(props) {
  const { children, title } = props;
  return (
    <div className="card shadow mt-3 text-dark">
      <div className="card-body">
        <SectionTitle title={title} />
        {children}
      </div>
    </div>
  );
}

SectionCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default SectionCard;
