import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { FULL_PATH_LOGIN } from "modules/account/Constants";

const AuthContextType = {
  access_token: localStorage.getItem("access_token"),
  refresh_token: localStorage.getItem("refresh_token"),
  logout: null,
  login: null,
};

const AuthContext = React.createContext(AuthContextType);

/**
 * AuthProvider
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [auth, setAuth] = React.useState(AuthContextType);
  const login = (tokens) => {
    setAuth(tokens);
  };
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setAuth({
      access_token: null,
      refresh_token: null,
    });
  };

  useEffect(() => {
    const handleLocalStorageChange = () => {
      if (!localStorage.getItem("access_token")) {
        logout();
      }
    };
    window.addEventListener("localStorageChange", handleLocalStorageChange);
    return () =>
      window.removeEventListener(
        "localStorageChange",
        handleLocalStorageChange
      );
  }, []);

  const value = React.useMemo(() => ({
    access_token: auth.access_token,
    refresh_token: auth.refresh_token,
    logout: () => logout(),
    login: (tokens) => login(tokens),
  }));
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

/**
 * useAuth
 * @returns {{access_token: string, refresh_token: string}}
 */
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

/**
 * RequireAuth
 * @returns {JSX.Element}
 * @constructor
 */
export function RequireAuth() {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.access_token) {
    return <Navigate to={FULL_PATH_LOGIN} state={{ from: location }} />;
  }
  return <Outlet />;
}
