import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

function IndeterminateCheckbox(props) {
  const { indeterminate, checked, onChange } = props;
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className="form-check-input"
      checked={checked}
      onChange={onChange}
    />
  );
}

IndeterminateCheckbox.defaultProps = {
  indeterminate: false,
  checked: false,
  onChange: () => {
    // Do nothing
  },
};

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default IndeterminateCheckbox;
