import { Modal as BootstrapModal } from "bootstrap";
import moment from "moment";

/**
 * Convert a FormData object to a plain object
 * @param formData
 * @param form
 */
export function form_data_to_object(
  formData: FormData,
  form?: HTMLFormElement
): any {
  const payload: Object = Object.fromEntries(formData.entries());
  const form_elements = form ? form.elements : null;
  if (form_elements) {
    Array.prototype.forEach.call(form_elements, (element) => {
      if (element instanceof HTMLInputElement) {
        if (["checkbox", "radio"].includes(element.type) && element.checked) {
          if (![null, ""].includes(element.value)) {
            payload[element.name] = element.value;
          } else {
            payload[element.name] = element.checked;
          }
        }
      }
    });
  }
  return payload;
}

/**
 * Close a modal
 * @param {string} modalId - The id of the modal to be closed
 */
export function closeModal(modalId) {
  const modal = BootstrapModal.getInstance(document.getElementById(modalId));
  modal.hide();
}

/**
 * Displays a loading modal with a title and text using the Swal library.
 *
 * @param {string} title - The title of the loading modal.
 * @param {string} text - The text to display in the loading modal.
 * @param {function} ConfirmSwal - The Swal function to use for displaying the loading modal.
 * @returns {void}
 */
const showLoading = (title, text, ConfirmSwal) => {
  const Swal = ConfirmSwal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
    },
    buttonsStyling: false,
  });
  Swal.fire({
    allowOutsideClick: () => !Swal.isLoading(),
    title,
    text,
  }).then(() => {
    // intentionally left empty
  });
  Swal.showLoading();
};
/**
 * Displays a SweetAlert notification when a task is done.
 *
 * @param {string} title - The title of the notification.
 * @param {string} message - The message to be displayed in the notification.
 * @param {object} ConfirmSwal - The SweetAlert instance to be used.
 * @param {string} [type="success"] - The type of notification. Default is "success".
 */
const showAlertOnDone = (title, message, ConfirmSwal, type = "success") => {
  const Swal = ConfirmSwal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
    },
    buttonsStyling: false,
  });
  Swal.fire(title, message, type).then(() => {
    // intentionally left empty
  });
};
export function showAlert(
  confirm,
  ConfirmSwal,
  preConfirmCallback,
  confirmCallback,
  denyCallback
) {
  const {
    title,
    message,
    confirm_button_label,
    action_done_title,
    action_done_message,
    confirm_button_color = "danger",
  } = confirm;
  preConfirmCallback();
  const Swal = ConfirmSwal.mixin({
    customClass: {
      confirmButton: `btn btn-${confirm_button_color} me-2`,
      cancelButton: "btn btn-secondary",
    },
    buttonsStyling: false,
  });
  Swal.fire({
    title,
    text: message,
    showCancelButton: true,
    confirmButtonText: confirm_button_label,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    preConfirm: () => {
      showLoading(title, message, ConfirmSwal);
    },
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback(
        () => {
          showAlertOnDone(action_done_title, action_done_message, ConfirmSwal);
        },
        (error_title, error_message) => {
          showAlertOnDone(error_title, error_message, ConfirmSwal, "error");
        }
      );
    } else if (result.isDismissed) {
      denyCallback();
    }
  });
}

export function formatDate(date: string, format: string) {
  if ([null, undefined, ""].includes(date)) {
    return "";
  }
  return moment(date).format(format);
}

export function formatAmount(amount: number, currency: string) {
  if ([null, undefined, ""].includes(amount)) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount);
}

/**
 * This function is used to get the outermost modal element from a given modal element.
 * It works by recursively checking the parent elements of the given modal element until it finds an element that is not a modal.
 * This is useful in scenarios where modals are nested within other modals, and you want to find the outermost modal.
 *
 * @param {HTMLElement} modalElement - The modal element to start the search from.
 * @returns {HTMLElement} - The outermost modal element.
 */
export function getOuterMostModalElement(modalElement) {
  const parentModal = modalElement.parentElement.closest(".modal");
  if (parentModal) {
    return getOuterMostModalElement(parentModal);
  }
  return modalElement;
}

/**
 * Opens a modal with the specified modalId.
 *
 * @param {string} modalId - The id of the modal element to open.
 * @param {string} [parentModalId] - The id of the parent modal element.
 * @param {boolean} [reopenParent=true] - Whether to reopen the parent modal after the modal is closed.
 * @return {BootstrapModal} - The BootstrapModal instance.
 */
export function openModal(modalId, parentModalId = null, reopenParent = true) {
  const modalElement = document.getElementById(modalId);
  if (parentModalId) {
    const parentModalElement = document.getElementById(parentModalId);
    const parentModal = BootstrapModal.getOrCreateInstance(parentModalElement);
    modalElement.addEventListener("hidden.bs.modal", () => {
      if (reopenParent) {
        parentModal.show();
      }
    });
    modalElement.addEventListener("show.bs.modal", () => {
      parentModal.hide();
    });
  }
  const modal = BootstrapModal.getOrCreateInstance(modalElement);
  modal.show();
  return modal;
}

/**
 * Closes a modal with the specified modalId.
 * @param {string} modalId - The id of the modal element to close.
 * @return {void}
 */
export function closeModalById(modalId) {
  const modal = BootstrapModal.getInstance(document.getElementById(modalId));
  if (modal) {
    modal.hide();
  }
}

/**
 * Formats a given amount of money with the specified currency into a string.
 *
 * @param {number} amount - The amount of money to format.
 * @param {string} currency - The currency symbol or code to use for formatting.
 * @return {string} - The formatted money string.
 */
export function getMoneyFormat(amount: number, currency: string) {
  if ([null, undefined, ""].includes(amount)) {
    return "";
  }
  const locale = navigator.language;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amount);
}

/**
 * Function to handle input changes for a form.
 *
 * @param {string} name - The name of the input field.
 * @param {any} value - The new value of the input field.
 * @param {Object} state - The current state of the form.
 * @param {function} setState - The function to update the state of the form.
 *
 * This function updates the state of the form by creating a new state object
 * with the updated value for the specified input field.
 */
export function handleInputChange(name, value, state, setState) {
  setState({ ...state, [name]: value });
}

/**
 * This function deletes all properties with null values from the input object.
 *
 * @param {Object} inputObj - The input object.
 * @returns {Object} - The input object with all null properties removed.
 */
export function deleteNullProperties(inputObj) {
  const obj = { ...inputObj };
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map((item) => {
        if (item && typeof item === "object" && !Array.isArray(item)) {
          return deleteNullProperties(item);
        }
        return item;
      });
    } else if (obj[key] && typeof obj[key] === "object") {
      obj[key] = deleteNullProperties(obj[key]);
    } else if (obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
}
