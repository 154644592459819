import { API_BASE_URL, PATH_MAIN_ROUTE } from "modules/core/Constants";

export const URL_GOOGLE_LOGIN: string = `${API_BASE_URL}/social-auth/google/`;
export const URL_GITHUB_LOGIN: string = `${API_BASE_URL}/social-auth/github/`;

// App routes
export const PATH_ACCOUNT: string = `${PATH_MAIN_ROUTE}/account`;
export const PATH_LOGIN: string = "login";
export const FULL_PATH_LOGIN: string = `${PATH_ACCOUNT}/${PATH_LOGIN}`;
export const GOOGLE_CLIENT_ID: string =
  process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
export const GITHUB_APP_ID: string = process.env.REACT_APP_GITHUB_APP_ID || "";
export const GITHUB_APP_SECRET: string =
  process.env.REACT_APP_GITHUB_APP_SECRET || "";
export const ENABLE_GOOGLE_LOGIN: boolean =
  process.env.REACT_APP_ENABLE_GOOGLE_LOGIN === "true";
export const ENABLE_GITHUB_LOGIN: boolean =
  process.env.REACT_APP_ENABLE_GITHUB_LOGIN === "true";
