import PropTypes from "prop-types";
import { useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { InstalledApp } from "modules/apps/Models";
import { showAlert } from "modules/base/utilities/Actions";
import ServersApiActions from "modules/servers/ApiActions";
import { VirtualMachine } from "modules/servers/Models";

function VirtualMachineClone(props) {
  const { resource, resource_type } = props;
  const ConfirmSwal = withReactContent(Swal);
  const serversApiActions = new ServersApiActions();
  const duplicateVirtualMachine = useCallback(() => {
    const confirm = {
      title: "Clone virtual machine?",
      message:
        "This action will create a new virtual machine with the same configuration and data as the current one. " +
        "The clone will be billed as a new virtual machine. Are you sure you want to continue?",
      confirm_button_label: "Clone virtual machine",
      action_done_title: "Virtual machine cloned",
      action_done_message: "The virtual machine has been cloned.",
      confirm_button_color: "success",
    };
    showAlert(
      confirm,
      ConfirmSwal,
      () => {
        // do nothing
      },
      (successCallback, errorCallback) => {
        serversApiActions.cloneVirtualMachine(
          resource.id,
          () => {
            successCallback();
          },
          (error_response) => {
            errorCallback(
              "Error cloning virtual machine",
              error_response.message,
            );
          },
        );
      },
      () => {
        // do nothing
      },
    );
  });
  let duplicateButton = <div />;
  if (resource_type === "vm") {
    duplicateButton = (
      <button
        type="button"
        className="btn btn-sm btn-outline-purple mt-2 ms-2"
        onClick={duplicateVirtualMachine}
      >
        <i className="bi bi-files" /> Clone
      </button>
    );
  }
  return duplicateButton;
}

VirtualMachineClone.propTypes = {
  resource: PropTypes.oneOfType([
    PropTypes.instanceOf(VirtualMachine),
    PropTypes.instanceOf(InstalledApp),
  ]).isRequired,
  resource_type: PropTypes.oneOf(["app", "vm"]).isRequired,
};

export default VirtualMachineClone;
