import PropTypes from "prop-types";
import Select from "react-select";

function SelectInput(props) {
  const {
    name,
    title,
    options,
    predicate,
    onChange,
    wrapperClasses,
    isDisabled,
    required,
    isMulti,
    isAddon,
    placeholder,
  } = props;
  const requiredAsterisk = required ? "*" : "";
  let value = options.find(predicate);
  if (isMulti) {
    value = options.filter(predicate);
  }
  const input = (
    <Select
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      required={required}
      isDisabled={isDisabled}
      isMulti={isMulti}
      placeholder={placeholder}
    />
  );
  if (isAddon) {
    return input;
  }
  return (
    <div className={`form-group ${wrapperClasses}`}>
      <label htmlFor={name}>
        {title} {requiredAsterisk}
      </label>
      {input}
    </div>
  );
}

SelectInput.defaultProps = {
  options: [],
  wrapperClasses: "",
  isDisabled: false,
  predicate: (option) => option,
  onChange: () => {
    // do nothing
  },
  required: false,
  isMulti: false,
  isAddon: false,
  placeholder: "Select...",
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  predicate: PropTypes.func,
  onChange: PropTypes.func,
  wrapperClasses: PropTypes.string,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAddon: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default SelectInput;
