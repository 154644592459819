import PropTypes from "prop-types";
import DebouncedInput from "modules/base/components/DebouncedInput";

function Search(props) {
  const { enableSearch, onChange, globalFilter } = props;
  if (!enableSearch) {
    return null;
  }
  return (
    <DebouncedInput
      value={globalFilter ?? ""}
      onChange={onChange}
      className="form-control"
      placeholder="Search..."
    />
  );
}

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  globalFilter: PropTypes.string.isRequired,
  enableSearch: PropTypes.bool.isRequired,
};

export default Search;
