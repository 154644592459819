import PropTypes from "prop-types";
import React, { useEffect } from "react";
import swal from "sweetalert2";

function SuccessActionIndicator(props) {
  const { success = {} } = props;
  useEffect(() => {
    if (success) {
      const { message } = success;
      swal.fire({
        text: message,
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  }, [success]);
  return <div />;
}

SuccessActionIndicator.defaultProps = {
  success: {},
};

SuccessActionIndicator.propTypes = {
  success: PropTypes.instanceOf(Object),
};

export default SuccessActionIndicator;
