import { Parser } from "html-to-react";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ServersApiActions from "../ApiActions";
import ActionInput from "modules/base/components/ActionInput";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { showAlert } from "modules/base/utilities/Actions";
import ContentCard from "modules/core/layouts/ContentCard";

/**
 * Backup plan selection component
 * @returns {JSX.Element}
 * @constructor
 */
function BackupPlanSelect(props) {
  const serversApiActions = new ServersApiActions();
  const {
    selected_template_id,
    no_of_md_cols,
    selected_vm_template_id,
    showCardContainer,
    showDisableConfirm,
    onEnableDisable,
    onPlanSelect,
    defaultToFreePlan,
    showConfirm,
    confirm,
  } = props;
  const { templates, error, isLoading } =
    serversApiActions.getVirtualMachineBackupTemplates([
      selected_vm_template_id,
    ]);
  const [enableBackup, setEnableBackup] = useState(true);
  const [selectedTemplateId, setSelectedTemplateId] =
    useState(selected_template_id);
  useEffect(() => {
    if (selectedTemplateId) {
      setEnableBackup(true);
    } else {
      setEnableBackup(false);
    }
  }, [selectedTemplateId]);
  useEffect(() => {
    setSelectedTemplateId(selected_template_id);
  }, [selected_template_id]);
  const ConfirmSwal = withReactContent(Swal);
  useEffect(() => {
    if ([null, undefined].includes(selectedTemplateId) && defaultToFreePlan) {
      const free_plan = templates.find((plan) =>
        [0, "0.0000"].includes(plan.monthly_plan_amount),
      );
      if (free_plan) {
        setSelectedTemplateId(free_plan.id);
        onPlanSelect(
          {
            target: {
              value: free_plan.id,
            },
          },
          free_plan.billing_plan,
        );
      }
    }
  }, [templates]);
  const getHandleInputChange = useCallback(
    (e, successCallback, errorCallback) => {
      setEnableBackup(e.target.checked);
      onEnableDisable(e.target.checked, successCallback, errorCallback);
    },
    [],
  );
  const getOnSelect = (plan) => (e) => {
    if (!showConfirm) {
      setSelectedTemplateId(Number(e.value));
      onPlanSelect(e, plan.billing_plan);
      return;
    }

    const previous_plan_id = selectedTemplateId;
    showAlert(
      confirm,
      ConfirmSwal,
      () => {
        setSelectedTemplateId(Number(e.value));
      },
      (successCallback, errorCallback) => {
        setSelectedTemplateId(Number(e.value));
        onPlanSelect(
          e,
          plan.billing_plan,
          () => {
            successCallback();
          },
          (error_title, error_message) => {
            errorCallback(error_title, error_message);
            setSelectedTemplateId(previous_plan_id);
          },
        );
      },
      () => {
        setSelectedTemplateId(previous_plan_id);
      },
    );
  };
  const planOptions = templates.map((plan) => {
    let { description } = plan;
    description = description.replace(/(\r\n|\n|\r)/gm, "<br />");
    description = new Parser().parse(description);
    return (
      <SelectOptionCard
        key={plan.id}
        label={plan.plan_price_display}
        value={plan.id}
        pre_label={plan.name}
        disabled={!enableBackup}
        isSelected={selectedTemplateId === plan.id}
        onSelect={getOnSelect(plan)}
        subtitle="per month"
        description={<span className="fs-7 fw-light">{description}</span>}
        section_name="backup_template"
      />
    );
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const plansOptions = [
    <ActionInput
      showConfirmOnDisable={showDisableConfirm}
      confirm={{
        title: "Disable Backups?",
        message:
          "This action will disable backups for your virtual machine. Are you sure you want to continue?",
        confirm_button_label: "Disable Backups",
        action_done_title: "Backups disabled",
        action_done_message: "The backups have been disabled.",
        confirm_button_color: "danger",
      }}
      key="enable-backup"
      label="Enable simple backups"
      name="enable_backup"
      handleInputChange={getHandleInputChange}
      isChecked={enableBackup}
      action_type="switch"
    />,
    <div
      key="backup-plans"
      className={`row row-cols-md-${no_of_md_cols} row-cols-1 gy-md-3 gy-1 mt-2`}
    >
      {planOptions}
    </div>,
  ];
  if (!showCardContainer) {
    return plansOptions;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <ContentCard
        header={
          <span>
            <i className="bi bi-alarm text-purple" /> Automated backups
          </span>
        }
      >
        {plansOptions}
      </ContentCard>
    </>
  );
}

BackupPlanSelect.defaultProps = {
  selected_template_id: null,
  selected_vm_template_id: null,
  no_of_md_cols: 4,
  showCardContainer: true,
  showDisableConfirm: false,
  onEnableDisable: () => {
    // do nothing
  },
  onPlanSelect: () => {
    // do nothing
  },
  defaultToFreePlan: false,
  showConfirm: false,
  confirm: {
    title: "Are you sure?",
    message: "You won't be able to revert this!",
    confirm_button_label: "Yes, do it!",
    action_done_title: "Done",
    action_done_message: "Your request has been processed successfully",
    confirm_button_color: "danger",
  },
};

BackupPlanSelect.propTypes = {
  selected_template_id: PropTypes.number,
  no_of_md_cols: PropTypes.number,
  selected_vm_template_id: PropTypes.number,
  showCardContainer: PropTypes.bool,
  showDisableConfirm: PropTypes.bool,
  onEnableDisable: PropTypes.func,
  onPlanSelect: PropTypes.func,
  defaultToFreePlan: PropTypes.bool,
  showConfirm: PropTypes.bool,
  confirm: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirm_button_label: PropTypes.string.isRequired,
    action_done_title: PropTypes.string.isRequired,
    action_done_message: PropTypes.string.isRequired,
    confirm_button_color: PropTypes.string,
  }),
};

export default BackupPlanSelect;
