import BaseClaimedCoupons from "modules/base/components/billing/BaseClaimedCoupons";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import BillingApiActions from "modules/billing/ApiActions";

function ClaimedCoupons() {
  const billingApiActions = new BillingApiActions();
  const { coupons, error, isLoading } =
    billingApiActions.getAppliedCoupons("i");

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  return <BaseClaimedCoupons coupons={coupons} error={error} />;
}

export default ClaimedCoupons;
