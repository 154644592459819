import PropTypes from "prop-types";
import React from "react";

/**
 * A card component that displays a header and a body.
 *
 * @param {Object} props
 * @param {string|Element} props.header - The header of the card.
 * @param {Node} props.children - The body of the card.
 */
function ContentCard(props) {
  const { header, children, padding } = props;
  return (
    <div className="card mb-4">
      <div className="card-header">{header}</div>
      <div className={`card-body ${padding}`}>{children}</div>
    </div>
  );
}

ContentCard.defaultProps = {
  padding: "p-0 p-md-3",
};

ContentCard.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
};

export default ContentCard;
