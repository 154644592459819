import PropTypes from "prop-types";
import CoreLocationSelect from "modules/core/components/CoreLocationSelect";
import ServersApiActions from "modules/servers/ApiActions";

/**
 * Location select component
 * @returns {JSX.Element}
 * @constructor
 */
function LocationSelect(props) {
  const serversApiActions = new ServersApiActions();
  const { locations, error, isLoading } =
    serversApiActions.getVirtualMachineLocations();
  const { onSelect } = props;

  return (
    <CoreLocationSelect
      error={error}
      isLoading={isLoading}
      locations={locations}
      onSelect={onSelect}
    />
  );
}

LocationSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default LocationSelect;
