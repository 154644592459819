import FeatureRequest from "modules/base/components/FeatureRequest";
import ContentContainer from "modules/core/layouts/ContentContainer";
import MainContainer from "modules/core/layouts/MainContainer";
import SideBar from "modules/core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";

function RequestFeature() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <FeatureRequest />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default RequestFeature;
