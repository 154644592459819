import { createColumnHelper } from "@tanstack/react-table";
import BillingApiActions from "../ApiActions";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SectionTitle from "modules/base/components/SectionTitle";
import DataTable from "modules/base/components/table/Table";
import { formatAmount, formatDate } from "modules/base/utilities/Actions";

function Payments() {
  const billingApiActions = new BillingApiActions();
  const { transactions, error, isLoading } =
    billingApiActions.getBillingTransactions();
  const columnHelper = createColumnHelper();
  const formatState = (state) => {
    if (["settled", "succeeded"].includes(state)) {
      return <span className="badge text-bg-success">Paid</span>;
    }
    if (state === "failed") {
      return <span className="badge text-bg-danger">Failed</span>;
    }
    if (state === "pending") {
      return <span className="badge text-bg-warning">Pending</span>;
    }
    if (state === "canceled") {
      return <span className="badge text-bg-danger">Canceled</span>;
    }
    if (state === "refunded") {
      return <span className="badge text-bg-secondary">Refunded</span>;
    }
    return <span className="badge text-bg-warning">Unpaid</span>;
  };
  const columns = [
    columnHelper.accessor("invoice_number", {
      header: "Invoice number",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      cell: (info) =>
        formatAmount(info.row.original.amount, info.row.original.currency),
    }),
    columnHelper.accessor("invoice_issue_date", {
      header: "Invoice issued on",
      cell: (info) => formatDate(info.renderValue(), "ll"),
    }),
    columnHelper.accessor("invoice_due_date", {
      header: "Due on",
      cell: (info) => formatDate(info.renderValue(), "ll"),
    }),
    columnHelper.accessor("state", {
      header: "",
      cell: (info) => formatState(info.renderValue()),
    }),
  ];
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <SectionTitle title="Payments" />
      <DataTable columns={columns} data={transactions} isLoading={isLoading} />
    </>
  );
}

export default Payments;
