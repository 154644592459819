import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwitchInput from "modules/base/components/inputs/SwitchInput";

function ActionInput(props) {
  const {
    label: action_label,
    icon,
    onClick,
    showConfirm,
    confirm,
    subject_id,
    button_classes,
    action_type,
    handleInputChange,
    isChecked,
    name,
    showConfirmOnDisable,
    showConfirmOnEnable,
    isButtonDisabled,
  } = props;
  let ConfirmSwal = withReactContent(Swal);
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);
  const showAlertOnDone = (title, message, type = "success") => {
    ConfirmSwal = ConfirmSwal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    ConfirmSwal.fire(title, message, type).then(() => {
      // intentionally left empty
    });
  };
  const showLoading = (title, text) => {
    ConfirmSwal = ConfirmSwal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    ConfirmSwal.fire({
      allowOutsideClick: () => !ConfirmSwal.isLoading(),
      title,
      text,
    }).then(() => {
      // intentionally left empty
    });
    ConfirmSwal.showLoading();
  };
  function showAlert(e) {
    const {
      title,
      message,
      confirm_button_label,
      action_done_title,
      action_done_message,
      confirm_button_color = "danger",
    } = confirm;
    const previousChecked = !e.target.checked;
    setChecked(e.target.checked);
    if (
      (showConfirmOnDisable && previousChecked) ||
      (showConfirmOnEnable && !previousChecked) ||
      showConfirm
    ) {
      ConfirmSwal = ConfirmSwal.mixin({
        customClass: {
          confirmButton: `btn btn-${confirm_button_color} me-2`,
          cancelButton: "btn btn-secondary",
        },
        buttonsStyling: false,
      });
      ConfirmSwal.fire({
        title,
        text: message,
        showCancelButton: true,
        confirmButtonText: confirm_button_label,
        allowOutsideClick: () => !ConfirmSwal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          showLoading(title, message);
          onClick(
            subject_id,
            () => {
              showAlertOnDone(action_done_title, action_done_message);
            },
            (error_title, error_message) => {
              showAlertOnDone(error_title, error_message, "error");
            }
          );
          handleInputChange(
            e,
            () => {
              showAlertOnDone(action_done_title, action_done_message);
            },
            (error_title, error_message) => {
              showAlertOnDone(error_title, error_message, "error");
            }
          );
        } else if (result.isDismissed) {
          setChecked(!e.target.checked);
        }
      });
    } else {
      onClick(subject_id, () => {
        // intentionally left empty
      });
      handleInputChange(e);
    }
  }
  const actionOnclick = useCallback((e) => {
    showAlert(e);
  });
  const actionOnChange = useCallback((e) => {
    showAlert(e);
  });
  if (action_type === "button") {
    return (
      <button
        className={button_classes}
        type="button"
        onClick={actionOnclick}
        key={action_label}
        disabled={isButtonDisabled}
      >
        <i className={`bi bi-${icon}`} /> {action_label}
      </button>
    );
  }
  return (
    <SwitchInput
      label={action_label}
      name={name}
      handleInputChange={actionOnChange}
      isChecked={checked}
    />
  );
}

ActionInput.defaultProps = {
  confirm: {
    title: "Are you sure?",
    message: "You won't be able to revert this!",
    confirm_button_label: "Yes, delete it!",
    action_done_title: "Deleted!",
    action_done_message: "Your file has been deleted.",
    confirm_button_color: "danger",
  },
  action_type: "button",
  handleInputChange: () => {
    // do nothing
  },
  isChecked: false,
  name: "",
  button_classes: "btn btn-primary",
  icon: "trash",
  onClick: () => {
    // do nothing
  },
  subject_id: 0,
  showConfirmOnDisable: false,
  showConfirmOnEnable: false,
  showConfirm: false,
  isButtonDisabled: false,
};

ActionInput.propTypes = {
  handleInputChange: PropTypes.func,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  showConfirm: PropTypes.bool,
  showConfirmOnDisable: PropTypes.bool,
  showConfirmOnEnable: PropTypes.bool,
  confirm: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirm_button_label: PropTypes.string.isRequired,
    action_done_title: PropTypes.string.isRequired,
    action_done_message: PropTypes.string.isRequired,
    confirm_button_color: PropTypes.string,
  }),
  subject_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  button_classes: PropTypes.string,
  action_type: PropTypes.string,
  isButtonDisabled: PropTypes.bool,
};

export default ActionInput;
