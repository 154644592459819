import { Modal as BootstrapModal } from "bootstrap";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { InstalledApp } from "modules/apps/Models";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";
import PlanSelection from "modules/servers/components/PlanSelection";
import { VirtualMachine } from "modules/servers/Models";

function PlanSubscription(props) {
  const { resource, resource_type } = props;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const serversApiActions = new ServersApiActions();
  let upgradeButton = <div />;
  const onUpgradeClick = useCallback((e) => {
    e.preventDefault();
    const upgradePlanModal = BootstrapModal.getOrCreateInstance(
      document.getElementById(`upgrade-plan-${resource.id}`),
    );
    upgradePlanModal.show();
  });
  const upgradePlan = useCallback(
    (e, _billing_plan_id, successCallback, errorCallback) => {
      const selected_plan = Number(e.value);
      serversApiActions.changeVirtualMachineTemplate(
        resource.id,
        selected_plan,
        () => {
          setSuccess("Plan upgraded successfully");
          setError(null);
          successCallback();
        },
        (error_response) => {
          setError({
            message: error_response.message,
          });
          setSuccess(null);
          errorCallback("Error upgrading plan", error_response.message);
        },
      );
    },
  );

  if (resource_type === "vm") {
    upgradeButton = (
      <button
        type="button"
        className="btn btn-sm btn-outline-purple mt-2"
        onClick={onUpgradeClick}
      >
        <i className="bi bi-arrow-up-circle" /> Upgrade
      </button>
    );
  }
  return (
    <>
      {upgradeButton}
      <Modal
        modal_title={`Upgrade ${resource_type}`}
        modal_id={`upgrade-plan-${resource.id}`}
        size="lg"
        isDismissible
      >
        <PlanSelection
          onSelect={upgradePlan}
          no_of_md_cols={2}
          selected_location={resource.location}
          selected_plan_id={resource.template}
          minimum_template_id={resource.template}
          showConfirm
          confirm={{
            title: "Change plan?",
            message:
              "This action will change the billing plan for this resource. Are you sure you want to continue?",
            confirm_button_label: "Change plan",
            action_done_title: "Plan changed",
            action_done_message: "The plan has been changed.",
            confirm_button_color: "success",
          }}
        />
        <NetworkMessageDisplay error={error} success={success} />
      </Modal>
    </>
  );
}

PlanSubscription.propTypes = {
  resource: PropTypes.oneOfType([
    PropTypes.instanceOf(VirtualMachine),
    PropTypes.instanceOf(InstalledApp),
  ]).isRequired,
  resource_type: PropTypes.oneOf(["app", "vm"]).isRequired,
};

export default PlanSubscription;
