import PropTypes from "prop-types";
import React, { useRef } from "react";
import useScreenType from "react-screentype-hook";
import { VncScreen } from "react-vnc";
import Modal from "../../base/components/Modal";
import { IS_DEVELOPMENT } from "../../core/Constants";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";

function VncViewer(props) {
  const ref = useRef();
  const screenType = useScreenType();
  const { isMobile } = screenType;
  const styles = {
    width: "58vw",
    height: "75vh",
  };
  if (isMobile) {
    styles.width = "88vw";
  }
  const { vm_name, vnc_auth_token, vnc_url } = props;
  const modal_id = `vnc-viewer-modal-${vm_name}`;
  const bearer_token = `base64url.bearer.authorization.k8s.io.${vnc_auth_token}`;
  const wsProtocols = [bearer_token, "base64.binary.k8s.io"];
  const rfbOptions = {
    wsProtocols,
  };
  return (
    <Modal modal_title="VNC Viewer" modal_id={modal_id}>
      <VncScreen
        url={vnc_url}
        scaleViewport
        focusOnClick
        debug={IS_DEVELOPMENT}
        retryDuration={1000}
        rfbOptions={rfbOptions}
        qualityLevel={9}
        resizeSession
        showDotCursor
        background="#000000"
        style={styles}
        ref={ref}
        loadingUI={<NetworkActivityIndicator />}
      />
    </Modal>
  );
}

VncViewer.propTypes = {
  vm_name: PropTypes.string.isRequired,
  vnc_auth_token: PropTypes.string.isRequired,
  vnc_url: PropTypes.string.isRequired,
};

export default VncViewer;
