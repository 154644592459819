import PropTypes from "prop-types";
import { useCallback } from "react";
import { Icon } from "modules/base/components/Icons";

function EditAction(props) {
  const { onClick, row, buttonColor } = props;
  const handleClick = useCallback(() => {
    onClick(row);
  }, [onClick, row]);
  return (
    <div className="ms-2">
      <button
        className={`btn btn-outline-${buttonColor} btn-sm`}
        type="button"
        onClick={handleClick}
      >
        <Icon icon="pencil-fill" /> Edit
      </button>
    </div>
  );
}

EditAction.defaultProps = {
  onClick: () => {},
  buttonColor: "primary",
};

EditAction.propTypes = {
  onClick: PropTypes.func,
  row: PropTypes.instanceOf(Object).isRequired,
  buttonColor: PropTypes.string,
};

export default EditAction;
