import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  PATH_DEPLOY_VM,
  PATH_LIST_VIRTUAL_MACHINES,
  PATH_MANAGE_VM,
} from "modules/servers/Constants";
import DeployVirtualMachine from "modules/servers/layouts/DeployVirtualMachine";
import ListVirtualMachines from "modules/servers/layouts/ListVirtualMachines";
import ManageVirtualMachine from "modules/servers/layouts/ManageVirtualMachine";

/**
 * Server Routes
 * @returns {JSX.Element}
 * @constructor
 */
function ServerRoutes() {
  return (
    <Routes>
      <Route path={PATH_DEPLOY_VM} element={<DeployVirtualMachine />} />
      <Route
        path={PATH_LIST_VIRTUAL_MACHINES}
        element={<ListVirtualMachines />}
      />
      <Route path={PATH_MANAGE_VM} element={<ManageVirtualMachine />} />
    </Routes>
  );
}

export default ServerRoutes;
