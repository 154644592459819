import PropTypes from "prop-types";
import React from "react";
import AppsApiActions from "modules/apps/ApiActions";
import { AvailableAppsLocation } from "modules/apps/Models";
import CorePlanSelection from "modules/core/components/CorePlanSelection";

/**
 * Plan selection component
 * @returns {JSX.Element}
 * @constructor
 */
function PlanSelection(props) {
  const appsApiActions = new AppsApiActions();
  const { onSelect, selected_location, app_id } = props;
  const cluster_ids = selected_location?.clusters;
  const { templates, error, isLoading } =
    appsApiActions.getAvailableAppTemplates(app_id, cluster_ids);
  return (
    <CorePlanSelection
      onSelect={onSelect}
      error={error}
      isLoading={isLoading}
      templates={templates}
    />
  );
}

PlanSelection.defaultProps = {
  selected_location: null,
};

PlanSelection.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected_location: PropTypes.instanceOf(AvailableAppsLocation),
  app_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PlanSelection;
