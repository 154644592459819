import React from "react";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import BillingApiActions from "modules/billing/ApiActions";

function BillingWalletBalance() {
  const billingApiActions = new BillingApiActions();
  const { wallet, error, isLoading } = billingApiActions.getBillingWallet("i");
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const balance_cents = wallet.balance_cents ?? 0;
  const currency = wallet.currency ?? "USD";
  const locale = navigator.language;
  const balance = Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(balance_cents / 100);
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <span className="badge text-bg-lime-green fs-6">Credit: {balance}</span>
    </>
  );
}

export default BillingWalletBalance;
