import { AuthenticationActions } from "../../account/Actions";
import NetworkRequests from "./NetworkRequests";

export default class PrivateApi extends NetworkRequests {
  constructor() {
    super();
    const authenticationActions = new AuthenticationActions();
    const tokens: Object = authenticationActions.retrieveAuthenticationTokens();
    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens.access_token}`,
    };
  }
}
