import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import AppsApiActions from "../ApiActions";

/**
 * Form Column
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FormColumn({ children }) {
  return <div className="col-md-6 col-lg-4">{children}</div>;
}

FormColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * App Filter Form Component
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function AppFilterForm({ filters, handleFiltersChange, isHidden }) {
  const appsApiActions = new AppsApiActions();

  const { locations, isLoading: locationsLoading } =
    appsApiActions.getAvailableAppsLocations();
  const { available_apps, isLoading: appsLoading } =
    appsApiActions.getAvailableApps();

  const stateOptions = [
    { value: "", label: "--States--" },
    { value: "running", label: "Running" },
    { value: "stopped", label: "Stopped" },
    { value: "deployed", label: "Deployed" },
    { value: "deleted", label: "Deleted" },
    { value: "starting", label: "Starting" },
  ];

  const [filterOptions, setFilterOptions] = useState({
    location: filters.location,
    app_type: filters.app_type,
    display_name: filters.display_name,
    hostname: filters.hostname,
    state: filters.state,
  });

  const handleFiltersSubmit = useCallback((e) => {
    e.preventDefault();
    const filterValues = Object.fromEntries(
      Object.entries(filterOptions).filter(
        ([_, value]) => value !== "" && value !== undefined,
      ),
    );
    handleFiltersChange(filterValues);
  });

  const handleInputChange = useCallback(
    (name, value) => {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: value,
      }));
    },
    [setFilterOptions],
  );

  const handleTextInputChange = useCallback(
    (e) => {
      handleInputChange(e.target.name, e.target.value);
    },
    [handleInputChange],
  );

  const handleSelectChange = useCallback(
    (name) => (selected) => {
      handleInputChange(name, selected.value);
    },
    [handleInputChange],
  );

  const locationOptions = [
    { value: "", label: "--Locations--" },
    ...(locations && !locationsLoading
      ? locations.map((location) => ({
          value: location.id,
          label: location.name,
        }))
      : []),
  ];

  const appOptions = [
    { value: "", label: "--App Types--" },
    ...(available_apps && !appsLoading
      ? available_apps.map((app) => ({ value: app.id, label: app.name }))
      : []),
  ];

  const createPredicate = useCallback(
    (field) => (option) => option.value === filterOptions[field],
    [filterOptions],
  );

  const appTypePredicate = createPredicate("app_type");
  const statePredicate = createPredicate("state");
  const locationPredicate = createPredicate("location");

  if (isHidden) return null;

  return (
    <Form
      handleSubmit={handleFiltersSubmit}
      id="App-filter-form"
      button_label="Filter Apps"
      items_flow="vertical"
    >
      <div className="row mb-3  col-lg-8">
        <FormColumn>
          <TextInput
            title=""
            inputType="text"
            name="display_name"
            controlFunc={handleTextInputChange}
            content={filterOptions.display_name}
            placeholder="Custom Name"
            className="form-control"
          />
        </FormColumn>
        <FormColumn>
          <TextInput
            title=""
            inputType="text"
            name="hostname"
            controlFunc={handleTextInputChange}
            content={filterOptions.hostname}
            placeholder="Hostname"
            className="form-control"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="location"
            title=""
            value={filterOptions.location}
            options={locationOptions}
            onChange={handleSelectChange("location")}
            predicate={locationPredicate}
            placeholder="Location"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="app_type"
            title=""
            value={filterOptions.app_type}
            options={appOptions}
            predicate={appTypePredicate}
            onChange={handleSelectChange("app_type")}
            placeholder="App Type"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="state"
            title=""
            options={stateOptions}
            onChange={handleSelectChange("state")}
            predicate={statePredicate}
            placeholder="App State"
          />
        </FormColumn>
      </div>
    </Form>
  );
}

AppFilterForm.defaultProps = {
  filters: {
    display_name: "",
    hostname: "",
    location: "",
    app_type: "",
    state: "",
  },
  handleFiltersChange: null,
  isHidden: false,
};

AppFilterForm.propTypes = {
  filters: PropTypes.shape({
    display_name: PropTypes.string,
    hostname: PropTypes.string,
    location: PropTypes.string,
    app_type: PropTypes.string,
    state: PropTypes.string,
  }),
  handleFiltersChange: PropTypes.func,
  isHidden: PropTypes.bool,
};

export default AppFilterForm;
