import NetworkRequests from "./NetworkRequests";

export default class PublicApi extends NetworkRequests {
  constructor() {
    super();
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
}
