import PropTypes from "prop-types";
import SubmitFormButton from "./SubmitFormButton";

function Form(props) {
  const {
    handleSubmit,
    children,
    button_color,
    button_label,
    id,
    isSubmitting,
    button_icon,
    padding,
    fullLengthButton,
    submitButtonEnabled,
    items_flow,
    use_as,
    submit_button_margin,
  } = props;
  let buttonClass = fullLengthButton ? "d-grid" : "d-grid d-md-flex";
  if (items_flow === "horizontal") {
    buttonClass = `${buttonClass} ms-md-2 ms-0 mt-md-4 mt-3`;
  }
  const onClick = use_as === "form" ? null : handleSubmit;
  const submitButton = (
    <div className={buttonClass}>
      <SubmitFormButton
        button_color={button_color}
        isSubmitting={isSubmitting}
        button_label={button_label}
        icon={button_icon}
        isInitiallyDisabled={!submitButtonEnabled}
        form_use_as={use_as}
        onClick={onClick}
        margin={submit_button_margin}
      />
    </div>
  );
  const FormTag = use_as === "form" ? "form" : "div";
  let formBody = (
    <>
      {children}
      {submitButton}
    </>
  );
  if (items_flow === "horizontal") {
    formBody = (
      <div className="d-flex justify-content-start align-items-md-center flex-md-row flex-column">
        {children}
        {submitButton}
      </div>
    );
  }
  const onSubmit = use_as === "form" ? handleSubmit : null;
  return (
    <FormTag onSubmit={onSubmit} className={padding} id={id}>
      {formBody}
    </FormTag>
  );
}

Form.defaultProps = {
  button_color: "purple",
  button_label: "Save",
  isSubmitting: false,
  button_icon: "check-circle",
  padding: "p-4",
  fullLengthButton: false,
  submitButtonEnabled: true,
  items_flow: "vertical",
  use_as: "form",
  submit_button_margin: 0,
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  button_color: PropTypes.string,
  button_label: PropTypes.string,
  id: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  button_icon: PropTypes.string,
  padding: PropTypes.string,
  fullLengthButton: PropTypes.bool,
  submitButtonEnabled: PropTypes.bool,
  items_flow: PropTypes.string,
  use_as: PropTypes.string,
  submit_button_margin: PropTypes.number,
};

export default Form;
