import BillingApiActions from "../ApiActions";
import BaseBillingDetails from "modules/base/components/billing/BaseBillingDetails";
import BillingDetailsForm from "modules/billing/components/BillingDetailsForm";

function BillingDetails() {
  const billingApiActions = new BillingApiActions();
  const { customer, error } = billingApiActions.getBillingCustomer("i");
  return (
    <BaseBillingDetails
      customer={customer}
      error={error}
      BillingDetailsForm={BillingDetailsForm}
    />
  );
}

export default BillingDetails;
