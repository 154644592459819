import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import DebouncedInput from "modules/base/components/DebouncedInput";
import FilterForm from "modules/servers/components/VmFilterForm";

/**
 * Virtual Machine Filters
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachinesFilter({
  search,
  filters,
  handleSearchChange,
  handleFiltersChange,
}) {
  const [isHidden, setIsHidden] = useState(true);

  const handleToggleCollapseForm = useCallback(() => {
    setIsHidden((prevState) => !prevState);
  }, []);

  const handleQuickSearch = useCallback((value) => {
    handleSearchChange(value);
  });

  const quickSearchBox = (
    <div className="col-12">
      <DebouncedInput
        value={search}
        onChange={handleQuickSearch}
        debounceTime={1000}
        className="form-control"
        placeholder="Search by name, hostname, IP address, or operating system"
      />
    </div>
  );

  const toggleButton = (
    <button
      onClick={handleToggleCollapseForm}
      className="btn bg-transparent border-0"
      aria-label={isHidden ? "Expand" : "Collapse"}
    >
      <span>
        FILTERS <i className={isHidden ? "bi bi-filter" : "bi bi-x-lg"} />
      </span>
    </button>
  );

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-12 col-md-9">{quickSearchBox}</div>
        <div className="col-12 col-md-3 mt-2 mt-md-0"> {toggleButton} </div>
      </div>
      {!isHidden && (
        <div id="collapseSearchForm">
          <FilterForm
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        </div>
      )}
    </div>
  );
}

VirtualMachinesFilter.defaultProps = {
  search: "",
  filters: {},
  handleSearchChange: null,
  handleFiltersChange: null,
};

VirtualMachinesFilter.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.shape({}),
  handleSearchChange: PropTypes.func,
  handleFiltersChange: PropTypes.func,
};

export default VirtualMachinesFilter;
