import { API_BASE_URL, PATH_MAIN_ROUTE } from "modules/core/Constants";

export const URL_BILLING_CUSTOMERS = `${API_BASE_URL}/billing/customers/`;
export const URL_BILLING_CUSTOMER = `${URL_BILLING_CUSTOMERS}:id/`;
export const URL_BILLING_INVOICES = `${API_BASE_URL}/billing/invoices/`;
export const URL_BILLING_TRANSACTIONS = `${API_BASE_URL}/billing/transactions/`;
export const URL_BILLING_PAYMENT_METHODS = `${API_BASE_URL}/billing/payment-methods/`;
export const URL_BILLING_PAYMENT_METHOD = `${URL_BILLING_PAYMENT_METHODS}:id/`;
export const URL_BILLING_CARDS = `${API_BASE_URL}/billing/cards/`;
export const URL_BILLING_CARD = `${URL_BILLING_CARDS}:id/`;
export const URL_BILLING_CARD_DEFAULT = `${URL_BILLING_CARD}default/`;
export const URL_BILLING_APPLY_COUPON = `${API_BASE_URL}/billing/customers/:id/apply-coupon/`;
export const URL_BILLING_APPLIED_COUPONS = `${API_BASE_URL}/billing/customers/:id/applied-coupons/`;
export const URL_BILLING_WALLET = `${API_BASE_URL}/billing/customers/:id/billing-wallet/`;

export const PATH_BILLING = `${PATH_MAIN_ROUTE}/billing`;
export const PATH_VIEW_BILLING = "view";
