import PropTypes from "prop-types";
import NavigationLink from "modules/base/components/NavigationLink";

function Button(props) {
  const { buttonColor, onClick, buttonLabel, buttonIcon } = props;
  return (
    <button
      type="button"
      className={`btn btn-${buttonColor}`}
      onClick={onClick}
    >
      <i className={`bi bi-${buttonIcon}`} /> {buttonLabel}
    </button>
  );
}

Button.defaultProps = {
  buttonColor: "primary",
  onClick: () => {},
  buttonIcon: "plus",
  buttonLabel: "Create",
};

Button.propTypes = {
  buttonColor: PropTypes.string,
  onClick: PropTypes.func,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string,
};

function CreateButton(props) {
  const {
    navigationPath,
    buttonLabel,
    buttonIcon,
    navigateOnClick,
    onClick,
    buttonColor,
  } = props;
  if (navigateOnClick) {
    return (
      <NavigationLink path={navigationPath} classes="d-grid">
        <button type="button" className={`btn btn-${buttonColor}`}>
          <i className={`bi bi-${buttonIcon}`} /> {buttonLabel}
        </button>
      </NavigationLink>
    );
  }
  return (
    <Button
      buttonColor={buttonColor}
      onClick={onClick}
      buttonIcon={buttonIcon}
      buttonLabel={buttonLabel}
    />
  );
}

CreateButton.defaultProps = {
  navigationPath: "",
  buttonLabel: "Create",
  buttonIcon: "plus",
  navigateOnClick: true,
  onClick: () => {},
  buttonColor: "primary",
};

CreateButton.propTypes = {
  navigationPath: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonIcon: PropTypes.string,
  navigateOnClick: PropTypes.bool,
  onClick: PropTypes.func,
  buttonColor: PropTypes.string,
};

export default CreateButton;
