import React from "react";
import BillingWalletBalance from "modules/billing/components/BillingWalletBalance";

function TopBar() {
  return (
    <header className="navbar sticky-top flex-md-nowrap p-0 bg-ocean-blue">
      <div className="col d-flex justify-content-end align-items-center flex-row me-2 pt-2">
        <BillingWalletBalance />
      </div>
    </header>
  );
}

export default TopBar;
