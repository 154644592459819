import PropTypes from "prop-types";
import React from "react";

/**
 * MainContainerCentered is a component that renders its children in a
 * container that is centered vertically and horizontally.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
function MainContainerCentered(props) {
  const { children } = props;
  return (
    <div className="container-fluid text-center vh-100 bg-ocean-blue">
      {children}
    </div>
  );
}

MainContainerCentered.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContainerCentered;
