import { Modal as BootstrapModal } from "bootstrap";
import React, { useCallback } from "react";
import {
  PATH_LIST_VIRTUAL_MACHINES,
  PATH_VIRTUAL_MACHINES,
} from "../Constants";
import BackupPlanSelect from "modules/servers/components/BackupPlanSelect";
import LocationSelect from "modules/servers/components/LocationSelect";
import OperatingSystemSelect from "modules/servers/components/OperatingSystemSelect";
import PlanSelection from "modules/servers/components/PlanSelection";
import Form from "modules/base/components/Form";
import NavigationLink from "modules/base/components/NavigationLink";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { form_data_to_object } from "modules/base/utilities/Actions";
import BillingApiActions from "modules/billing/ApiActions";
import ClaimCoupon from "modules/billing/components/ClaimCoupon";
import DebitCreditCardForm from "modules/billing/components/DebitCreditCardForm";
import ContentCard from "modules/core/layouts/ContentCard";
import ServersApiActions from "modules/servers/ApiActions";
import TextInput from "modules/base/components/inputs/TextInput";

/**
 * Virtual Machine Deploy
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachineDeploy() {
  const [deploying, setDeploying] = React.useState(false);
  const [deployed, setDeployed] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [displayName, setDisplayName] = React.useState("");
  const [selectedBillingPlan, setSelectedBillingPlan] = React.useState(null);
  const [selectedBackupBillingPlan, setSelectedBackupBillingPlan] =
    React.useState(null);
  const serversApiActions = new ServersApiActions();
  const billingApiActions = new BillingApiActions();
  const [error, setError] = React.useState(null);
  const { customer } = billingApiActions.getBillingCustomer("i");
  let has_minimum_cards_allowed = false;
  if (customer) {
    has_minimum_cards_allowed = customer.has_minimum_cards_allowed;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setDeploying(true);
    const form = document.getElementById("deploy-vm");
    const formData = new FormData(form);
    const payload = form_data_to_object(formData, form);
    serversApiActions.createVirtualMachine(
      payload,
      () => {
        setDeploying(false);
        setDeployed(true);
      },
      (error_response) => {
        setDeploying(false);
        setDeployed(false);
        setError(error_response);
      },
    );
  };
  function addCard() {
    const addCardModal = BootstrapModal.getOrCreateInstance(
      document.getElementById("add-card"),
    );
    addCardModal.show();
  }
  const handleAddCard = useCallback(() => {
    if (!has_minimum_cards_allowed) {
      addCard();
    }
  });
  const getHandleSumit = useCallback((e) => {
    handleSubmit(e);
  });
  const handleChangeCustomName = useCallback((e) => {
    setDisplayName(e.target.value);
  });
  const getSetSelectedPlan = useCallback((e, billing_plan_id) => {
    setSelectedPlan(Number(e.value));
    setSelectedBillingPlan(Number(billing_plan_id));
  });
  const getSetSelectedBackupPlan = useCallback((e, billing_plan_id) => {
    setSelectedBackupBillingPlan(Number(billing_plan_id));
  });
  const getSetSelectedLocation = useCallback((e, selected_location) => {
    setSelectedLocation(selected_location);
  });
  let deployVM = (
    <Form
      handleSubmit={getHandleSumit}
      button_label="Launch"
      id="deploy-vm"
      isSubmitting={deploying}
      submitButtonEnabled={has_minimum_cards_allowed}
      padding="p-1"
    >
      <LocationSelect onSelect={getSetSelectedLocation} />
      <PlanSelection
        onSelect={getSetSelectedPlan}
        selected_location={selectedLocation}
      />
      <BackupPlanSelect
        selected_vm_template_id={selectedPlan}
        defaultToFreePlan
        onPlanSelect={getSetSelectedBackupPlan}
      />
      <OperatingSystemSelect
        onSelect={handleAddCard}
        selected_location={selectedLocation}
      />
      <div className="mb-4 mt-4">
        <TextInput
          controlFunc={handleChangeCustomName}
          name="display_name"
          content={displayName}
          className="form-control"
          placeholder="VM Display Name"
        />
      </div>
      <ClaimCoupon
        selected_billing_plan_id={selectedBillingPlan}
        selected_backup_billing_plan_id={selectedBackupBillingPlan}
      />
    </Form>
  );
  if (deployed) {
    deployVM = (
      <>
        <div className="alert alert-success" role="alert">
          Your virtual machine is ready to use.
        </div>
        <NavigationLink
          path={`${PATH_VIRTUAL_MACHINES}/${PATH_LIST_VIRTUAL_MACHINES}`}
        >
          Take me there <i className="bi bi-arrow-right" />
        </NavigationLink>
      </>
    );
  }
  const card_padding = deployed ? "p-3" : "p-0 p-md-3";
  return (
    <>
      <DebitCreditCardForm isDismissible={false} />
      <ContentCard header="Deploy Virtual Machine" padding={card_padding}>
        <NetworkMessageDisplay error={error} />
        {deployVM}
      </ContentCard>
    </>
  );
}

export default VirtualMachineDeploy;
