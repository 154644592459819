import PropTypes from "prop-types";
import { ReactComponent as AmexIcon } from "modules/base/assets/images/amex.svg";
import { ReactComponent as CreditChipIcon } from "modules/base/assets/images/chip-credit.svg";
import { ReactComponent as DebitChipIcon } from "modules/base/assets/images/chip-debit.svg";
import { ReactComponent as DiscoverIcon } from "modules/base/assets/images/discover.svg";
import { ReactComponent as MasterCardIcon } from "modules/base/assets/images/mastercard.svg";
import { ReactComponent as VisaIcon } from "modules/base/assets/images/visa.svg";
import CheckboxInput from "modules/base/components/inputs/CheckboxInput";

function BillingCard(props) {
  const { billingCard, tooltipContent, handleSetDefault, handleDelete } = props;
  const brandIcons = {
    visa: VisaIcon,
    mastercard: MasterCardIcon,
    amex: AmexIcon,
    discover: DiscoverIcon,
  };
  const chipIcons = {
    credit: CreditChipIcon,
    debit: DebitChipIcon,
  };
  const BrandIcon = brandIcons[billingCard.brand.toLowerCase()] ?? VisaIcon;
  const ChipIcon = chipIcons[billingCard.funding] ?? CreditChipIcon;
  const card_number = `**** **** **** ${billingCard.last4}`;
  return (
    <div className="col mb-2">
      <div className="card bg-secondary bg-gradient border-0 rounded-4 shadow">
        <div className="card-body">
          <div className="row row-cols-2">
            <div className="text-start">
              <CheckboxInput
                checked={billingCard.default}
                name="default"
                showTooltip
                tooltip_content={tooltipContent}
                handleChange={handleSetDefault}
              />
            </div>
            <div className="text-end">
              <BrandIcon width={50} height={50} />
            </div>
          </div>
          <div className="text-start">
            <ChipIcon width={30} height={30} />
          </div>
          <div className="pt-2 text-white">{card_number}</div>
          <div className="row text-white">
            <div className="col-3 fs-7">valid until</div>
            <div className="col-8 fs-6">
              {billingCard.exp_month}/{billingCard.exp_year}
            </div>
          </div>
          <div className="row row-cols-2">
            <div className="text-white">{billingCard.display_name}</div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={handleDelete}
              >
                <i className="bi bi-trash" /> Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BillingCard.propTypes = {
  billingCard: PropTypes.instanceOf(Object).isRequired,
  tooltipContent: PropTypes.string.isRequired,
  handleSetDefault: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default BillingCard;
