export const IS_DEVELOPMENT: any = process.env.NODE_ENV === "development";
export const SERVER_BASE_URL: string = IS_DEVELOPMENT
  ? "http://localhost:8000"
  : "";
export const API_BASE_URL: string = `${SERVER_BASE_URL}/api`;

// App routes
export const PATH_MAIN_ROUTE: string = "/app";
export const PATH_FEEDBACK: string = `${PATH_MAIN_ROUTE}/feedback`;
export const PATH_FEATURE_REQUEST: string = "feature-request";
export const CANNY_BOARD_TOKEN = process.env.REACT_APP_CANNY_BOARD_TOKEN;
export const ELASTIC_APM_URL = process.env.REACT_APP_ELASTIC_APM_URL;
export const ELASTIC_APM_SERVICE_NAME =
  process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME;
