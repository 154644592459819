import PropTypes from "prop-types";
import React, { useCallback } from "react";
import ReactCountryFlag from "react-country-flag";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ContentCard from "modules/core/layouts/ContentCard";

/**
 * Location select component
 * @returns {JSX.Element}
 * @constructor
 */
function CoreLocationSelect(props) {
  const { onSelect, locations, error, isLoading } = props;
  const handleSelect = (e) => {
    const selectedLocation = locations.find(
      (location) => location.id === Number(e.value),
    );
    onSelect(e, selectedLocation);
  };
  const getHandleSelect = useCallback((e) => {
    handleSelect(e);
  });

  const locationOptions = locations.map((location) => (
    <SelectOptionCard
      key={location.id}
      value={location.id}
      label={location.name}
      subtitle={location.country_name}
      section_name="location"
      onSelect={getHandleSelect}
      icon={
        <ReactCountryFlag
          countryCode={location.country_code}
          svg
          style={{
            width: "2em",
            height: "2em",
          }}
        />
      }
    />
  ));
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  return (
    <>
      <NetworkMessageDisplay error={error} />
      <ContentCard
        header={
          <span>
            <i className="bi bi-geo-alt text-purple text-purple" /> Location
          </span>
        }
      >
        <div className="row row-cols-md-4 row-cols-1 gy-md-3 gy-1">
          {locationOptions}
        </div>
      </ContentCard>
    </>
  );
}

CoreLocationSelect.defaultProps = {
  error: null,
};

CoreLocationSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  locations: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, Object]),
  isLoading: PropTypes.bool.isRequired,
};

export default CoreLocationSelect;
