import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../AuthProvider";
import NavigationLink from "../../base/components/NavigationLink";
import BillingApiActions from "modules/billing/ApiActions";
import "react-tooltip/dist/react-tooltip.css";
import { navigateToUrl } from "modules/core/utilities/Navigation";

/**
 * A React component that renders a list of sidebar items.
 * @param {Object} props - The props object.
 * @param {Array} props.main_items - The main items to display in the sidebar. Each item should have a name, an icon, and a boolean isActive property.
 * @param {Array} props.footer_items - The footer items to display in the sidebar. Each item should have a name and an icon.
 * @returns {JSX.Element} A JSX element that represents the sidebar items.
 */
function SidebarItems(props) {
  const auth = useAuth();
  const handleClick = (path, isExternal) => {
    navigateToUrl(path, {
      target: isExternal ? "_blank" : "_self",
      replace: false,
    });
  };
  const location = useLocation();
  const { pathname } = location;
  const { main_items, footer_items, is_open } = props;
  const label_class_name = is_open ? "d-inline" : "d-none";
  const handleLogout = useCallback(() => {
    auth.logout();
  }, []);
  const billingApiActions = new BillingApiActions();
  const { customer } = billingApiActions.getBillingCustomer("i");
  useEffect(() => {
    if (customer?.email) {
      window.clarity("identify", customer.email);
    }
  }, [customer]);
  const mainItems = main_items.map((item) => {
    const { name, icon, path, base_path } = item;
    const active =
      pathname.includes(base_path) && !["", "/", "coming-soon"].includes(path);
    let button_class_name = "nav-link text-black text-start fw-medium";
    if (is_open) {
      button_class_name += " w-100";
    }
    let li_class_name = "d-grid";
    if (active) {
      button_class_name += " active bg-purple text-white fw-medium";
      li_class_name = "nav-item d-grid";
    }
    let navigation_button = (
      <NavigationLink path={`${base_path}/${path}`}>
        <button type="button" className={button_class_name}>
          <i className={`bi ${icon}`} />{" "}
          <span className={label_class_name}>{name}</span>
        </button>
      </NavigationLink>
    );
    if (path === "coming-soon") {
      button_class_name += " coming-soon";
      navigation_button = (
        <button
          type="button"
          className={button_class_name}
          data-tooltip-content="coming soon"
        >
          <i className={`bi ${icon}`} />{" "}
          <span className={label_class_name}>{name}</span>
        </button>
      );
    }
    return (
      <li className={li_class_name} key={uuidv4()}>
        {navigation_button}
      </li>
    );
  });
  const footerItems = footer_items.map((item) => {
    const { name, icon, path, isExternal } = item;
    const getHandleClick = useCallback(() => {
      handleClick(path, isExternal);
    }, [path, isExternal]);
    return (
      <li className="d-grid" key={uuidv4()}>
        <button
          type="button"
          className="nav-link text-black text-start"
          onClick={getHandleClick}
        >
          <i className={`bi ${icon}`} />{" "}
          <span className={label_class_name}>{name}</span>
        </button>
      </li>
    );
  });
  return (
    <>
      <ul className="nav nav-pills flex-column mb-auto">
        {mainItems}
        <Tooltip anchorSelect=".coming-soon" place="top" id="coming-soon" />
      </ul>
      <hr />
      <ul className="nav nav-pills flex-column mb-1">
        {footerItems}
        <li className="d-grid">
          <button
            type="button"
            className="nav-link text-black text-start fw-medium"
            onClick={handleLogout}
          >
            <i className="bi bi-box-arrow-right" />{" "}
            <span className={label_class_name}>Logout</span>
          </button>
        </li>
      </ul>
    </>
  );
}

SidebarItems.propTypes = {
  main_items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      base_path: PropTypes.string.isRequired,
      active_paths: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  footer_items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      isExternal: PropTypes.bool,
    }),
  ).isRequired,
  is_open: PropTypes.bool.isRequired,
};

export default SidebarItems;
