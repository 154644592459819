import PropTypes from "prop-types";
import React from "react";

/**
 * Main container for the app
 * @param {Object} props
 * @param {ReactNode} props.children
 */
function MainContainer(props) {
  const { children } = props;
  return (
    <div className="container-fluid bg-ocean-blue d-flex p-0 min-vh-100">
      {children}
    </div>
  );
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContainer;
