import PropTypes from "prop-types";
import React from "react";

function SubmitFormButton(props) {
  const {
    button_label,
    button_color,
    isSubmitting,
    icon,
    onClick,
    isInitiallyDisabled,
    form_use_as,
    margin,
  } = props;
  const disabled = isSubmitting || isInitiallyDisabled ? "disabled" : "";
  let submitButton = (
    <button
      type={form_use_as === "form" ? "submit" : "button"}
      className={`btn btn-${button_color} m-${margin}`}
      disabled={disabled}
      onClick={onClick}
    >
      <i className={`bi bi-${icon}`} /> {button_label}
    </button>
  );
  if (isSubmitting) {
    submitButton = (
      <button
        type={form_use_as === "form" ? "submit" : "button"}
        className={`btn btn-${button_color} m-${margin}`}
        disabled={disabled}
      >
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        {button_label}
      </button>
    );
  }
  return submitButton;
}

SubmitFormButton.defaultProps = {
  button_label: "Submit",
  button_color: "primary",
  isSubmitting: false,
  icon: "check-circle",
  onClick: () => {
    // do nothing
  },
  isInitiallyDisabled: false,
  form_use_as: "form",
  margin: 0,
};

SubmitFormButton.propTypes = {
  button_label: PropTypes.string,
  button_color: PropTypes.string,
  isSubmitting: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isInitiallyDisabled: PropTypes.bool,
  form_use_as: PropTypes.string,
  margin: PropTypes.number,
};

export default SubmitFormButton;
