import PropTypes from "prop-types";
import React from "react";
import AppsApiActions from "modules/apps/ApiActions";
import CoreLocationSelect from "modules/core/components/CoreLocationSelect";

/**
 * Location select component
 * @returns {JSX.Element}
 * @constructor
 */
function LocationSelect(props) {
  const appsApiActions = new AppsApiActions();
  const { locations, error, isLoading } =
    appsApiActions.getAvailableAppsLocations();
  const { onSelect } = props;

  return (
    <CoreLocationSelect
      error={error}
      isLoading={isLoading}
      locations={locations}
      onSelect={onSelect}
    />
  );
}

LocationSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default LocationSelect;
