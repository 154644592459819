import PropTypes from "prop-types";
import NavigationLink from "modules/base/components/NavigationLink";

/**
 * Launch VM button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function LaunchVMButton(props) {
  const { urlPath, description } = props;
  return (
    <div className="d-grid justify-content-md-end mb-2">
      <NavigationLink path={urlPath} classes="d-grid">
        <button type="button" className="btn btn-purple flex-sm-grow-1">
          <i className="bi bi-rocket-takeoff" /> {description}
        </button>
      </NavigationLink>
    </div>
  );
}

LaunchVMButton.propTypes = {
  urlPath: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default LaunchVMButton;
