import PropTypes from "prop-types";
import React from "react";
import ActionsDropdown from "modules/base/components/ActionsDropdown";

function DropdownAction({
  actionsList,
  row,
  removeTextWhiteClass,
  dropdownLabel,
}) {
  return (
    <ActionsDropdown
      label={dropdownLabel || null}
      actionsList={
        Array.isArray(actionsList)
          ? actionsList.map((action) => ({
              ...action,
              onClick: () => action.onClick(row),
            }))
          : []
      }
      subject_id={row.subject_id}
      removeTextWhiteClass={removeTextWhiteClass}
    />
  );
}

DropdownAction.propTypes = {
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  removeTextWhiteClass: PropTypes.bool,
  dropdownLabel: PropTypes.string,
};

DropdownAction.defaultProps = {
  removeTextWhiteClass: true,
  dropdownLabel: null,
};

export default DropdownAction;
