import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLoginButton } from "react-social-login-buttons";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import { useAuth } from "../../../AuthProvider";
import NetworkActivityIndicator from "../../base/components/NetworkActivityIndicator";
import {
  PATH_LIST_VIRTUAL_MACHINES,
  PATH_VIRTUAL_MACHINES,
} from "../../servers/Constants";
import { AuthenticationActions } from "../Actions";
import { AuthenticationApi } from "../ApiActions";
import { ENABLE_GOOGLE_LOGIN, GOOGLE_CLIENT_ID } from "../Constants";
import { Tokens } from "../Models";

/**
 * Google Login Component
 * @returns {JSX.Element}
 * @constructor
 */
function GoogleLogin() {
  const authenticationApi = new AuthenticationApi();
  const authenticationActions = new AuthenticationActions();
  const [is_authenticating, setAuthenticating] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const onLoginStart = () => {
    setAuthenticating(true);
  };
  const handleGoogleLogin = (profile) => {
    const { access_token: google_access_token } = profile;
    authenticationApi.loginWithGoogle(
      google_access_token,
      (response_data: any) => {
        const { access_token, refresh_token } = response_data;
        const tokens = new Tokens(access_token, refresh_token);
        authenticationActions.storeAuthenticationTokens(tokens).then(() => {
          auth.login(tokens);
          setAuthenticating(false);
          navigate(`${PATH_VIRTUAL_MACHINES}/${PATH_LIST_VIRTUAL_MACHINES}`);
        });
      },
      (error: any) => {
        setAuthenticating(false);
        console.log("error", error);
      }
    );
  };
  const google_login_display = ENABLE_GOOGLE_LOGIN ? "block" : "none";
  let googleLoginButton = (
    <LoginSocialGoogle
      client_id={GOOGLE_CLIENT_ID}
      onLoginStart={onLoginStart}
      scope="profile email"
      discoveryDocs="claims_supported"
      access_type="offline"
      typeResponse="accessToken"
      onResolve={({ data }: IResolveParams) => {
        handleGoogleLogin(data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <GoogleLoginButton
        className={`fs-5 text-nowrap d-${google_login_display}`}
      >
        Continue with Google
      </GoogleLoginButton>
    </LoginSocialGoogle>
  );
  if (is_authenticating) {
    googleLoginButton = <NetworkActivityIndicator />;
  }
  return (
    <div className="col d-flex justify-content-center">{googleLoginButton}</div>
  );
}

export default GoogleLogin;
