import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./layouts/Home";

/**
 * @description Dashboard Routes
 * @returns {JSX.Element}
 * @constructor
 */
function DashboardRoutes() {
  return (
    <Routes>
      <Route path="" element={<Home />} />
    </Routes>
  );
}

export default DashboardRoutes;
