// eslint-disable-next-line max-classes-per-file
import { BaseModel } from "modules/base/models/Base";
import { ClusterLocation, Template } from "modules/core/Models";

export class InstalledApp extends BaseModel {
  id: number;

  name: string;

  display_name: string;

  cpu: number;

  memory: number;

  hostname: string;

  ready: boolean;

  state: string;

  state_reason: string;

  workload_running_percentage: number;

  version: string;

  version_number: string;

  location: string;

  location_country_code: string;

  domain_connection_settings: Object;

  has_custom_domain: boolean;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.display_name = data.display_name;
    this.cpu = data.cpu;
    this.memory = data.memory;
    this.hostname = data.hostname;
    this.ready = data.ready;
    this.state = data.status;
    this.state_reason = data.status_reason;
    this.workload_running_percentage = data.workload_running_percentage;
    this.version = data.version;
    this.version_number = data.version_number;
    this.location = data.location;
    this.location_country_code = data.location_country_code;
    this.domain_connection_settings = data.domain_connection_settings ?? {};
    this.has_custom_domain = data.has_custom_domain;
  }
}

export class AvailableApps extends BaseModel {
  id: number;

  name: string;

  description: string;

  icon: string;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.icon = data.icon;
  }
}

export class AvailableAppVersion extends BaseModel {
  id: number;

  version: string;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.version = data.version;
  }
}

export class AvailableAppsLocation extends ClusterLocation {
  constructor(data: any = {}) {
    super(data);
  }
}

export class AvailableAppTemplate extends Template {
  constructor(data: any = {}) {
    super(data);
  }
}
