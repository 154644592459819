import React, { useState, useCallback } from "react";
import AppsApiActions from "modules/apps/ApiActions";
import InstalledAppActions from "modules/apps/components/InstalledAppActions";
import InstalledAppCard from "modules/apps/components/InstalledAppCard";
import {
  PATH_APPS,
  PATH_AVAILABLE_APPS,
  PATH_LIST_INSTALLED_APPS,
} from "modules/apps/Contants";
import NavigationLink from "modules/base/components/NavigationLink";
import DebouncedInput from "modules/base/components/DebouncedInput";
import AppFilterForm from "modules/apps/components/AppFilterForm";

function InstalledAppsList() {
  const appsApiActions = new AppsApiActions();

  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [isHidden, setIsHidden] = useState(true);

  const handleToggleCollapseForm = useCallback(() => {
    setIsHidden((prevState) => !prevState);
  }, []);

  const { installed_apps, isLoading, error } = appsApiActions.getInstalledApps(
    search,
    filters,
  );
  const available_apps_path = `${PATH_APPS}/${PATH_AVAILABLE_APPS}/${PATH_LIST_INSTALLED_APPS}`;

  const handleQuickSearch = useCallback((value) => {
    setSearch(value);
  }, []);

  const handleFiltersChange = useCallback((filtersOptions) => {
    setFilters(filtersOptions);
  }, []);

  const installedApps = installed_apps.map((app) => (
    <InstalledAppCard
      key={app.id}
      actions={<InstalledAppActions subject_id={app.id} row={app} />}
      isClickable={false}
      installed_app={app}
    />
  ));

  const launchAppButton = (
    <div className="d-grid justify-content-md-end mb-2 col-md-4 col-12">
      <NavigationLink path={available_apps_path} classes="d-grid">
        <button type="button" className="btn btn-purple w-100">
          <i className="bi bi-rocket-takeoff" /> Launch App
        </button>
      </NavigationLink>
    </div>
  );

  const quickSearchBox = (
    <div className="col-md-6 col-12 mb-2 mb-md-0">
      <DebouncedInput
        value={search}
        onChange={handleQuickSearch}
        debounceTime={1000}
        className="form-control"
        placeholder="Search for name, hostname, app type"
      />
    </div>
  );

  const toggleButton = (
    <div className="col-md-2 col-12 mb-2 mb-md-0 text-md-end">
      <button
        onClick={handleToggleCollapseForm}
        className="btn bg-transparent border-0 w-100"
        aria-label={isHidden ? "Expand" : "Collapse"}
      >
        <span>
          FILTERS <i className={isHidden ? "bi bi-filter" : "bi bi-x-lg"} />
        </span>
      </button>
    </div>
  );

  return (
    <div className="flex flex-col">
      <div className="row">
        {quickSearchBox}
        {toggleButton}
        {launchAppButton}
      </div>
      <AppFilterForm
        filters={filters}
        handleFiltersChange={handleFiltersChange}
        isHidden={isHidden}
      />
      <div className="flex flex-row flex-wrap">{installedApps}</div>
      {isLoading ? (
        <div className="alert alert-warning mt-4">Loading...</div>
      ) : null}
      {!isLoading && !installed_apps.length ? (
        <div className="alert alert-success mt-4">
          No any managed app found, click{" "}
          <NavigationLink path={available_apps_path}> here </NavigationLink>to
          launch one.
        </div>
      ) : null}
      {error && <div className="alert alert-danger mt-4">{error.message}</div>}
    </div>
  );
}

export default InstalledAppsList;
