import PropTypes from "prop-types";

/**
 * A generic text input component
 * @returns {JSX.Element}
 * @constructor
 */
function TextInput({
  inputType,
  title,
  name,
  controlFunc,
  content,
  placeholder,
  required,
  isDisabled,
  wrapperClasses,
  groupClass,
  PreAddon,
  PostAddon,
  accept,
}) {
  const requiredAsterisk = required ? "*" : "";
  let Input = (
    <input
      className="form-control"
      id={name}
      name={name}
      type={inputType}
      value={content}
      onChange={controlFunc}
      placeholder={placeholder}
      required={required}
      disabled={isDisabled}
    />
  );
  if (inputType === "file") {
    Input = (
      <input
        className="form-control"
        id={name}
        name={name}
        type={inputType}
        onInput={controlFunc}
        placeholder={placeholder}
        required={required}
        disabled={isDisabled}
        accept={accept}
      />
    );
  }
  return (
    <div className={`form-group ${wrapperClasses}`}>
      <label htmlFor={name}>
        {title} {requiredAsterisk}
      </label>
      <div className={groupClass}>
        {PreAddon}
        {Input}
        {PostAddon}
      </div>
    </div>
  );
}

TextInput.defaultProps = {
  inputType: "text",
  content: "",
  placeholder: "",
  required: false,
  isDisabled: false,
  wrapperClasses: "",
  groupClass: "",
  PreAddon: null,
  PostAddon: null,
  accept: null,
};

TextInput.propTypes = {
  inputType: PropTypes.oneOf(["text", "number", "file", "password", "email"]),
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  wrapperClasses: PropTypes.string,
  groupClass: PropTypes.string,
  PreAddon: PropTypes.element,
  PostAddon: PropTypes.element,
  accept: PropTypes.string,
};

export default TextInput;
