import PropTypes from "prop-types";
import React from "react";
import MainContainerCentered from "../../core/layouts/MainContainerCentered";

/**
 * LoginContainer
 *
 * @param {Object} props
 * @param {ReactNode} props.children
 */
function LoginContainer(props) {
  const { children } = props;
  return (
    <MainContainerCentered>
      <main className="col-md-6 col-sm-12 m-auto position-absolute top-50 start-50 translate-middle w-auto">
        <div className="card shadow-lg rounded-4 border-0">
          <div className="card-body p-md-5 p-sm-1">{children}</div>
        </div>
      </main>
    </MainContainerCentered>
  );
}

LoginContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginContainer;
