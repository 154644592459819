import PropTypes from "prop-types";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DisplayIcon from "modules/base/components/DisplayIcon";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { showAlert } from "modules/base/utilities/Actions";
import ContentCard from "modules/core/layouts/ContentCard";

/**
 * Plan selection component
 * @returns {JSX.Element}
 * @constructor
 */
function CorePlanSelection(props) {
  const {
    onSelect,
    templates,
    error,
    isLoading,
    no_of_md_cols,
    selected_plan_id,
    showConfirm,
    confirm,
    minimum_template_id,
  } = props;
  let Icon = null;
  const [selectedPlanId, setSelectedPlanId] = useState(selected_plan_id);
  const ConfirmSwal = withReactContent(Swal);
  const getOnSelect = (plan) => (e) => {
    if (!showConfirm) {
      setSelectedPlanId(Number(e.value));
      onSelect(e, plan.billing_plan);
      return;
    }

    const previous_plan_id = selectedPlanId;
    showAlert(
      confirm,
      ConfirmSwal,
      () => {
        setSelectedPlanId(Number(e.value));
      },
      (successCallback, errorCallback) => {
        setSelectedPlanId(Number(e.value));
        onSelect(
          e,
          plan.billing_plan,
          () => {
            successCallback();
          },
          (error_title, error_message) => {
            errorCallback(error_title, error_message);
            setSelectedPlanId(previous_plan_id);
          },
        );
      },
      () => {
        setSelectedPlanId(previous_plan_id);
      },
    );
  };
  const minimum_template = minimum_template_id
    ? templates.find((template) => template.id === minimum_template_id)
    : null;
  const planOptions = templates.map((plan) => {
    if (plan.display_icon_type === "bootstrap") {
      Icon = <i className={`bi bi-${plan.display_icon}`} />;
    } else {
      Icon = (
        <DisplayIcon icon_name={plan.display_icon} size={60} color="#3B4044" />
      );
    }
    const rootDiskSize = plan.root_disk_size ? (
      <span className="text-muted">{`${plan.root_disk_size} GB storage`}</span>
    ) : (
      ""
    );
    let isDisabled = false;
    if (minimum_template) {
      isDisabled = plan.root_disk_size < minimum_template.root_disk_size;
    }
    return (
      <SelectOptionCard
        key={plan.id}
        value={plan.id}
        label={plan.plan_price_display}
        subtitle="per month"
        onSelect={getOnSelect(plan)}
        isSelected={selectedPlanId === plan.id}
        icon={Icon}
        icon_position="top"
        disabled={isDisabled}
        description={
          <div className="row">
            <span className="text-muted">{`${plan.cpu} Core`}</span>
            <span className="text-muted">{`${plan.memory} GB memory`}</span>
            {rootDiskSize}
            <span className="text-muted">{`${plan.bandwidth} TB transfer`}</span>
          </div>
        }
        section_name="template"
      />
    );
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <ContentCard
        header={
          <span>
            <i className="bi bi-sliders  text-purple" /> Plan
          </span>
        }
      >
        <div
          className={`row row-cols-md-${no_of_md_cols} row-cols-1 gy-md-3 gy-1`}
        >
          {planOptions}
        </div>
      </ContentCard>
    </>
  );
}

CorePlanSelection.defaultProps = {
  error: null,
  no_of_md_cols: 4,
  selected_plan_id: null,
  showConfirm: false,
  confirm: {
    title: "Are you sure?",
    message: "You won't be able to revert this!",
    confirm_button_label: "Yes, do it!",
    action_done_title: "Done",
    action_done_message: "Your request has been processed successfully",
    confirm_button_color: "danger",
  },
  minimum_template_id: null,
};

CorePlanSelection.propTypes = {
  onSelect: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, Object]),
  isLoading: PropTypes.bool.isRequired,
  no_of_md_cols: PropTypes.number,
  selected_plan_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showConfirm: PropTypes.bool,
  confirm: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirm_button_label: PropTypes.string.isRequired,
    action_done_title: PropTypes.string.isRequired,
    action_done_message: PropTypes.string.isRequired,
    confirm_button_color: PropTypes.string,
  }),
  minimum_template_id: PropTypes.number,
};

export default CorePlanSelection;
