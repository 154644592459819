import { Modal as BootstrapModal } from "bootstrap";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import AppsApiActions from "modules/apps/ApiActions";
import AvailableAppVersions from "modules/apps/components/AvailableAppVersions";
import LocationSelect from "modules/apps/components/LocationSelect";
import PlanSelection from "modules/apps/components/PlanSelection";
import { FULL_PATH_LIST_INSTALLED_APPS } from "modules/apps/Contants";
import Form from "modules/base/components/Form";
import NavigationLink from "modules/base/components/NavigationLink";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { form_data_to_object } from "modules/base/utilities/Actions";
import BillingApiActions from "modules/billing/ApiActions";
import ClaimCoupon from "modules/billing/components/ClaimCoupon";
import DebitCreditCardForm from "modules/billing/components/DebitCreditCardForm";
import ContentCard from "modules/core/layouts/ContentCard";

function AvailableAppLaunch() {
  const { id: app_id } = useParams();
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [selectedBillingPlan, setSelectedBillingPlan] = React.useState(null);
  const [launching, setLaunching] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [launched, setLaunched] = React.useState(false);

  const appsApiActions = new AppsApiActions();
  const billingApiActions = new BillingApiActions();
  const { available_app, isLoading } = appsApiActions.getAvailableApp(app_id);
  const { customer } = billingApiActions.getBillingCustomer("i");
  let has_minimum_cards_allowed = false;
  if (customer) {
    has_minimum_cards_allowed = customer.has_minimum_cards_allowed;
  }
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setLaunching(true);
    setError(null);
    const form = document.getElementById("launch-app");
    const formData = new FormData(form);
    const payload = form_data_to_object(formData, form);
    appsApiActions.createInstalledApp(
      payload,
      () => {
        setLaunching(false);
        setLaunched(true);
      },
      (error_response) => {
        setLaunching(false);
        setLaunched(false);
        setError(error_response);
      },
    );
  });

  const getSetSelectedLocation = useCallback((e, selected_location) => {
    setSelectedLocation(selected_location);
  });
  const getSetSelectedPlan = useCallback((e, billing_plan_id) => {
    setSelectedBillingPlan(Number(billing_plan_id));
  });
  function addCard() {
    const addCardModal = BootstrapModal.getOrCreateInstance(
      document.getElementById("add-card"),
    );
    addCardModal.show();
  }
  const handleAddCard = useCallback(() => {
    if (!has_minimum_cards_allowed) {
      addCard();
    }
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  let launchApp = (
    <Form
      handleSubmit={handleSubmit}
      button_label="Launch"
      id="launch-app"
      isSubmitting={launching}
      submitButtonEnabled
      padding="p-1"
    >
      <LocationSelect onSelect={getSetSelectedLocation} />
      <PlanSelection
        selected_location={selectedLocation}
        onSelect={getSetSelectedPlan}
        app_id={app_id}
      />
      <AvailableAppVersions
        app_id={available_app.id}
        onSelect={handleAddCard}
      />
      <ClaimCoupon selected_billing_plan_id={selectedBillingPlan} />
    </Form>
  );
  if (launched) {
    launchApp = (
      <>
        <div className="alert alert-success" role="alert">
          Your app is ready to use.
        </div>
        <NavigationLink path={FULL_PATH_LIST_INSTALLED_APPS}>
          Take me there <i className="bi bi-arrow-right" />
        </NavigationLink>
      </>
    );
  }
  const card_padding = launched ? "p-3" : "p-0 p-md-3";
  return (
    <>
      <DebitCreditCardForm isDismissible={false} />
      <ContentCard
        header={`Launch Managed ${available_app.name}`}
        padding={card_padding}
      >
        <NetworkMessageDisplay error={error} />
        {launchApp}
      </ContentCard>
    </>
  );
}

export default AvailableAppLaunch;
