import PropTypes from "prop-types";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

/**
 * A phone number input component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function PhoneNumberInput(props) {
  const { title, name, controlFunc, content, placeholder, required } = props;
  const requiredAsterisk = required ? "*" : "";
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {title} {requiredAsterisk}
      </label>
      <PhoneInput
        defaultCountry="US"
        value={content}
        onChange={controlFunc}
        placeholder={placeholder}
        name={name}
        id={name}
        rules={{
          required,
        }}
        numberInputProps={{
          required,
          className: "form-control",
        }}
      />
    </div>
  );
}

PhoneNumberInput.defaultProps = {
  content: "",
  placeholder: "",
  required: false,
};

PhoneNumberInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default PhoneNumberInput;
