import React from "react";
import AvailableAppsList from "modules/apps/components/AvailableAppsList";
import ContentContainer from "modules/core/layouts/ContentContainer";
import MainContainer from "modules/core/layouts/MainContainer";
import SideBar from "modules/core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";

function ListAvailableApps() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <AvailableAppsList />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default ListAvailableApps;
