import React from "react";
import InstalledAppsList from "modules/apps/components/InstalledAppsList";
import ContentContainer from "modules/core/layouts/ContentContainer";
import MainContainer from "modules/core/layouts/MainContainer";
import SideBar from "modules/core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";

function ListInstalledApps() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <InstalledAppsList />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default ListInstalledApps;
