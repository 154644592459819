import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  PATH_AVAILABLE_APPS,
  PATH_INSTALLED_APPS,
  PATH_LAUNCH_AVAILABLE_APP,
  PATH_LIST_AVAILABLE_APPS,
  PATH_LIST_INSTALLED_APPS,
} from "modules/apps/Contants";
import LaunchAvailableApp from "modules/apps/layouts/LaunchAvailableApp";
import ListAvailableApps from "modules/apps/layouts/ListAvailableApps";
import ListInstalledApps from "modules/apps/layouts/ListInstalledApps";

function AppsRoutes() {
  return (
    <Routes>
      <Route
        path={`${PATH_INSTALLED_APPS}/*`}
        element={
          <Routes>
            <Route
              path={PATH_LIST_INSTALLED_APPS}
              element={<ListInstalledApps />}
            />
          </Routes>
        }
      />
      <Route
        path={`${PATH_AVAILABLE_APPS}/*`}
        element={
          <Routes>
            <Route
              path={PATH_LIST_AVAILABLE_APPS}
              element={<ListAvailableApps />}
            />
            <Route
              path={PATH_LAUNCH_AVAILABLE_APP}
              element={<LaunchAvailableApp />}
            />
          </Routes>
        }
      />
    </Routes>
  );
}

export default AppsRoutes;
