import { flexRender } from "@tanstack/react-table";
import PropTypes from "prop-types";

function Head(props) {
  const { table } = props;
  return table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <th key={header.id} className="text-nowrap text-center text-md-start">
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </th>
      ))}
    </tr>
  ));
}

Head.propTypes = {
  table: PropTypes.shape({
    getHeaderGroups: PropTypes.func.isRequired,
  }).isRequired,
};

export default Head;
