import { Modal as BootstrapModal } from "bootstrap";
import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback } from "react";
import ReactCountryFlag from "react-country-flag";
import { Progress } from "react-sweet-progress";
import { ToastContainer } from "react-toastify";
import ConfigureDomain from "modules/apps/components/ConfigureDomain";
import { InstalledApp } from "modules/apps/Models";
import CopyToClipboardButton from "modules/base/components/CopyToClipboardButton";
import NavigationLink from "modules/base/components/NavigationLink";
import PlanSubscription from "modules/servers/components/PlanSubscription";
import "react-toastify/dist/ReactToastify.css";
import VirtualMachineClone from "modules/servers/components/VirtualMachineClone";
import { VirtualMachine } from "modules/servers/Models";
import OperatingSystemManage from "modules/servers/components/OperatingSystemManage";
import "react-sweet-progress/lib/style.css";

/**
 * Virtual Machine Card
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ResourceCard(props) {
  const {
    resource,
    actions,
    allowIPCopy,
    isClickable,
    manage_path,
    resource_type,
  } = props;
  const resourceLocationFlag: Element = (
    <ReactCountryFlag
      countryCode={resource.location_country_code}
      className="ms-2"
      svg
      style={{
        width: "1.2em",
        height: "1.2em",
      }}
    />
  );
  const resource_state_map = {
    running: "success",
    deployed: "success",
    stopped: "danger",
    creating: "info",
    stopping: "warning",
    starting: "warning",
    off: "secondary",
    terminating: "warning",
    paused: "secondary",
    progressing: "info",
  };
  const resource_state_lower = resource.state.toLowerCase();
  const resource_state_badge_color: string =
    resource_state_map[resource_state_lower] ?? "secondary";
  const configureDomain = useCallback(() => {
    const configureDomainModal = BootstrapModal.getOrCreateInstance(
      document.getElementById(`configure-domain-${resource.id}`)
    );
    configureDomainModal.show();
  });
  let resourceStatusIndicator: Element = (
    <span
      className={`badge text-bg-${resource_state_badge_color} align-self-start`}
    >
      {_.capitalize(resource_state_lower)}
    </span>
  );
  let statusReasonIndicator = <div />;
  if (
    resource_type === "app" &&
    !["stopped", "deleted"].includes(resource.state_reason) &&
    resource.workload_running_percentage < 100
  ) {
    resourceStatusIndicator = (
      <Progress
        type="circle"
        percent={resource.workload_running_percentage}
        width={40}
        strokeWidth={10}
      />
    );
  } else if (
    resource_state_lower !== "running" &&
    resource.state_reason !== "" &&
    resource.state_reason
  ) {
    statusReasonIndicator = (
      <span className="badge text-bg-info align-self-start ms-2">
        {" "}
        {_.startCase(resource.state_reason)}{" "}
      </span>
    );
  }
  let copyIPButton = <div />;
  if (allowIPCopy) {
    copyIPButton = (
      <CopyToClipboardButton text={resource.ip_address} noun="IP address" />
    );
  }
  const Version =
    resource_type === "app" ? (
      <p className="card-text fw-normal">
        <i className="bi bi-git" /> {resource.version_number}
      </p>
    ) : (
      <p className="card-text fw-normal">
        <i className="bi bi-disc" /> {resource.os_version}
      </p>
    );
  const Disk =
    resource_type === "app" ? (
      ""
    ) : (
      <>
        <i className="bi bi-dot" />
        <i className="bi bi-hdd" /> {resource.root_disk_size} GiB
      </>
    );
  let Hostname = resource.hostname;

  let changeHostnameButton = <div />;
  if (resource_type === "app") {
    let change_hostname_label = "Add your own domain";
    if (resource.has_custom_domain) {
      change_hostname_label = "Change domain";
    }
    changeHostnameButton = (
      <button
        type="button"
        className="btn btn-sm btn-outline-purple"
        onClick={configureDomain}
      >
        <i className="bi bi-globe" /> {change_hostname_label}
      </button>
    );
  }
  if (resource_type === "app") {
    const url = `https://${resource.hostname}`;
    Hostname = (
      <NavigationLink path={url} classes="text-primary" isExternal>
        {resource.hostname}
      </NavigationLink>
    );
  }
  return (
    <>
      <ToastContainer />
      <ConfigureDomain
        installed_app_id={resource.id}
        domain_connection_settings={resource.domain_connection_settings}
      />
      <div className="card shadow mt-3 text-dark" key={resource.id}>
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {resourceStatusIndicator} {statusReasonIndicator}
            </div>
            <div className="align-self-end">{actions}</div>
          </div>
          <NavigationLink
            path={manage_path}
            classes="text-dark"
            isClickable={isClickable}
          >
            <div className="d-flex mt-3 mt-md-0">
              <p className="card-title h3 d-none d-md-block text-black">
                {resource.display_name ? resource.display_name: Hostname}
              </p>
              <p className="card-title fs-6 d-block d-md-none">
                {resource.display_name ? resource.display_name: Hostname}
              </p>
              {resourceLocationFlag}
            </div>

            {resource.display_name ? (
              <p>
                <i className="bi bi-link-45deg" /> {Hostname}
              </p>
            ) : null}
            {changeHostnameButton}
            <h6 className="card-subtitle mb-2 text-muted">
              {resource.ip_address} {copyIPButton}
            </h6>
            {Version}
            <p className="card-text fw-normal">
              <i className="bi bi-cpu" /> {resource.cpu} Core
              <i className="bi bi-dot" />
              <i className="bi bi-memory" /> {resource.memory} GiB
              {Disk}
            </p>
          </NavigationLink>
          <PlanSubscription resource={resource} resource_type={resource_type} />
          <VirtualMachineClone
            resource={resource}
            resource_type={resource_type}
          />
          <OperatingSystemManage vm={resource} resource_type={resource_type} />
        </div>
      </div>
    </>
  );
}

ResourceCard.defaultProps = {
  allowIPCopy: false,
  isClickable: true,
};

ResourceCard.propTypes = {
  resource: PropTypes.oneOfType([
    PropTypes.instanceOf(VirtualMachine),
    PropTypes.instanceOf(InstalledApp),
  ]).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  allowIPCopy: PropTypes.bool,
  isClickable: PropTypes.bool,
  manage_path: PropTypes.string.isRequired,
  resource_type: PropTypes.oneOf(["app", "vm"]).isRequired,
};

export default ResourceCard;
