import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppsApiActions from "modules/apps/ApiActions";
import { FULL_PATH_LIST_INSTALLED_APPS } from "modules/apps/Contants";
import ActionsDropdown from "modules/base/components/ActionsDropdown";
import AppDisplayNameForm from "modules/apps/components/AppDisplayNameForm";

function InstalledAppActions(props) {
  const appsApiActions = new AppsApiActions();
  const [showCustomNameModal, setShowCustomNameModal] = useState(false);
  const { subject_id, row } = props;
  const { name, display_name } = row;
  const navigate = useNavigate();

  const actions_list = [
    {
      label: "Rename",
      icon: "pencil",
      onClick: () => setShowCustomNameModal(true),
      showConfirm: false,
    },
    {
      label: "Delete",
      icon: "trash",
      onClick: (id, successCallback, errorCallback) => {
        appsApiActions.deleteInstalledApp(
          id,
          () => {
            navigate(FULL_PATH_LIST_INSTALLED_APPS);
            successCallback();
          },
          (error) => errorCallback("Error deleting app", error),
        );
      },
      showConfirm: true,
      confirm: {
        title: "Delete this app?",
        message:
          "This action cannot be undone. All data will be permanently deleted.",
        confirm_button_label: "Delete",
        action_done_title: "Deleted",
        action_done_message: "The app has been deleted.",
      },
    },
  ];

  return (
    <>
      {showCustomNameModal && (
        <AppDisplayNameForm
          appDisplayName={display_name || name}
          appId={subject_id}
        />
      )}
      <ActionsDropdown
        label="Actions"
        actionsList={actions_list}
        subject_id={subject_id}
      />
    </>
  );
}

InstalledAppActions.propTypes = {
  subject_id: PropTypes.number.isRequired,
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string,
  }).isRequired,
};

export default InstalledAppActions;
