import PropTypes from "prop-types";
import VirtualMachineActions from "modules/servers/components/VirtualMachineActions";
import VirtualMachineCard from "modules/servers/components/VirtualMachineCard";
import NavigationLink from "modules/base/components/NavigationLink";

/**
 * Virtual Machine Card
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachinesListCard(props) {
  const { isLoading, virtualMachines, vmDeployPath } = props;

  if (isLoading) {
    return <div className="alert alert-warning">Loading...</div>;
  }
  if (!virtualMachines.length) {
    return (
      <div className="alert alert-success">
        No any virtual machine found, click{" "}
        <NavigationLink path={vmDeployPath}> here</NavigationLink> to launch
        one.
      </div>
    );
  }

  return (
    <div className="flex flex-row flex-wrap">
      {virtualMachines.map((virtualMachine) => (
        <VirtualMachineCard
          virtual_machine={virtualMachine}
          key={virtualMachine.id}
          actions={
            <VirtualMachineActions
              subject_id={virtualMachine.id}
              row={virtualMachine}
            />
          }
        />
      ))}
    </div>
  );
}

VirtualMachinesListCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  virtualMachines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
};

export default VirtualMachinesListCard;
