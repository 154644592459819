import { useCallback } from "react";
import BillingApiActions from "../ApiActions";
import BasePaymentMethods from "modules/base/components/billing/BasePaymentMethods";
import DebitCreditCardForm from "modules/billing/components/DebitCreditCardForm";

function PaymentMethods() {
  const billingApiActions = new BillingApiActions();
  const {
    cards: billing_cards,
    error,
    isLoading,
  } = billingApiActions.getBillingCards();
  const { customer } = billingApiActions.getBillingCustomer("i");

  function deleteCard(card_id, successCallback, errorCallback) {
    billingApiActions.deleteBillingCard(
      card_id,
      successCallback,
      errorCallback,
    );
  }

  function setDefaultCard(card_id, successCallback, errorCallback) {
    billingApiActions.setDefaultBillingCard(
      card_id,
      successCallback,
      errorCallback,
    );
  }

  const getHandleDeleteCard = useCallback(
    (id, successCallback, errorCallback) => {
      deleteCard(id, successCallback, errorCallback);
    },
  );

  const getHandleSetDefaultCard = useCallback(
    (id, successCallback, errorCallback) => {
      setDefaultCard(id, successCallback, errorCallback);
    },
  );

  return (
    <BasePaymentMethods
      billing_cards={billing_cards}
      error={error}
      isLoading={isLoading}
      customer={customer}
      handleDeleteCard={getHandleDeleteCard}
      handleSetDefaultCard={getHandleSetDefaultCard}
      DebitCreditCardForm={DebitCreditCardForm}
    />
  );
}

export default PaymentMethods;
