import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { ReactComponent as PoweredByStripe } from "modules/base/assets/images/powered-by-stripe.svg";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SubmitFormButton from "modules/base/components/SubmitFormButton";
import SuccessActionIndicator from "modules/base/components/SuccessActionIndicator";

function BaseDebitCreditCardForm(props) {
  const {
    isDismissible,
    error,
    success,
    isSubmitting,
    handleSubmit,
    setIsSubmitting,
    setError,
    setSuccess,
    submit_button_color,
  } = props;
  const options = {
    classes: {
      base: "form-control form-control-lg",
    },
  };
  const stripe = useStripe();
  const elements = useElements();

  function submitForm() {
    if (!stripe || !elements) {
      return;
    }
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then((payload) => {
      if (payload.error) {
        setError(payload.error);
        setIsSubmitting(false);
      } else {
        setError(null);
        const card_details = {
          token: payload.token.id,
          name: document.querySelector("input[name=name]").value,
        };
        handleSubmit(card_details, cardElement);
      }
    });
  }

  return (
    <>
      <SuccessActionIndicator success={success} />
      <Modal
        modal_title="Add Card"
        modal_id="add-card"
        size="md"
        isDismissible={isDismissible}
        moveModalToOuterMost={false}
      >
        <NetworkMessageDisplay error={error} success={success} />
        <div className="mb-3">
          <CardElement options={options} />
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Name on card"
          name="name"
          required
        />
        <div className="d-grid mt-4">
          <SubmitFormButton
            button_color={submit_button_color}
            isSubmitting={isSubmitting}
            button_label="Complete"
            onClick={submitForm}
          />
          <div className="mt-2 d-flex justify-content-end">
            <PoweredByStripe width={120} />
          </div>
        </div>
      </Modal>
    </>
  );
}

BaseDebitCreditCardForm.defaultProps = {
  isDismissible: true,
  error: null,
  success: null,
  submit_button_color: "purple",
};

BaseDebitCreditCardForm.propTypes = {
  isDismissible: PropTypes.bool,
  error: PropTypes.instanceOf(Object),
  success: PropTypes.instanceOf(Object),
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  submit_button_color: PropTypes.string,
};

export default BaseDebitCreditCardForm;
