import PublicApi from "../core/utilities/PublicApi";
import { URL_GITHUB_LOGIN, URL_GOOGLE_LOGIN } from "./Constants";

export class AuthenticationApi extends PublicApi {
  loginWithGoogle(
    access_token: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    this.postAPIRequest(
      URL_GOOGLE_LOGIN,
      { social_access_token: access_token },
      successCallback,
      errorCallback
    );
  }

  loginWithGithub(
    access_token: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    this.postAPIRequest(
      URL_GITHUB_LOGIN,
      { social_access_token: access_token },
      successCallback,
      errorCallback
    );
  }
}
