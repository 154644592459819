import React from "react";
import ContentContainer from "modules/core/layouts/ContentContainer";
import MainContainer from "modules/core/layouts/MainContainer";
import SideBar from "modules/core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";
import VirtualMachineBackups from "modules/servers/components/VirtualMachineBackups";
import VirtualMachineDetails from "modules/servers/components/VirtualMachineDetails";

function ManageVirtualMachine() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <VirtualMachineDetails />
          <VirtualMachineBackups />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default ManageVirtualMachine;
