import PublicApi from "modules/core/utilities/PublicApi";
import { URL_MARKETING_FOCUS_ITEMS } from "modules/marketing/Contants";
import { FocusItem } from "modules/marketing/Models";

// eslint-disable-next-line import/prefer-default-export
export class MarketingApiActions extends PublicApi {
  /**
   * Get marketing focus items
   * @returns {{isLoading: boolean, error: any, focus_items: FocusItem[]}}
   */
  getFocusItems() {
    const { data, error, isLoading } = this.getAPIRequest(
      URL_MARKETING_FOCUS_ITEMS,
    );
    const focusItem = new FocusItem();
    const focus_items = focusItem.fromArray(data);
    return { focus_items, error, isLoading };
  }
}
