import _ from "lodash";
import PropTypes from "prop-types";
import ActionInput from "modules/base/components/ActionInput";

function DeleteAction(props) {
  const { row, onClick, objectType } = props;
  const objectTypeLabel = _.startCase(objectType);
  return (
    <ActionInput
      label="Delete"
      icon="trash-fill"
      subject_id={row.id}
      showConfirm
      confirm={{
        title: `Delete ${objectTypeLabel}`,
        message: `Are you sure you want to delete this ${objectType}?`,
        confirm_button_label: "Delete",
        confirm_button_color: "danger",
        action_done_message: `${objectTypeLabel} deleted`,
        action_done_title: "Success",
      }}
      button_classes="btn btn-sm btn-danger ms-2"
      action_type="button"
      onClick={onClick}
    />
  );
}

DeleteAction.defaultProps = {
  onClick: () => {},
  objectType: "",
};

DeleteAction.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  objectType: PropTypes.string,
};

export default DeleteAction;
