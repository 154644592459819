import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getOuterMostModalElement } from "modules/base/utilities/Actions";

/**
 * Modal container
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ModalContainer({
  children,
  modal_id,
  size,
  isCentered,
  onModalReady,
  moveModalToOuterMost,
}) {
  const modalClass = isCentered ? "modal-dialog-centered" : "";
  const [isMoved, setIsMoved] = useState(false);
  useEffect(() => {
    return () => {
      const orphanedBackdrop = document.querySelector(".modal-backdrop");
      const openModals = document.querySelectorAll(".modal.show");
      if (orphanedBackdrop && openModals.length === 0) {
        orphanedBackdrop.parentNode.removeChild(orphanedBackdrop);
      }
    };
  }, []);
  useEffect(() => {
    if (moveModalToOuterMost && !isMoved) {
      const modalElement = document.getElementById(modal_id);
      if (!modalElement) {
        return;
      }
      if (!modalElement.parentElement.contains(modalElement)) {
        return;
      }
      if (modalElement.parentElement.closest(".modal")) {
        const outerMostModal = getOuterMostModalElement(modalElement);
        outerMostModal.parentElement.appendChild(modalElement);
        setIsMoved(true);
      } else {
        modalElement.parentElement.appendChild(modalElement);
        setIsMoved(true);
      }
      _.debounce(() => {
        onModalReady();
      }, 300)();
    }
  }, [modal_id]);
  return (
    <div
      className="modal fade"
      id={modal_id}
      tabIndex="-1"
      aria-labelledby={modal_id}
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className={`modal-dialog ${modalClass} modal-${size}`}>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}

ModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  modal_id: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  isCentered: PropTypes.bool.isRequired,
  onModalReady: PropTypes.func.isRequired,
  moveModalToOuterMost: PropTypes.bool.isRequired,
};

function Modal({
  children,
  modal_title,
  modal_id,
  size,
  isDismissible,
  isCentered,
  onModalReady,
  moveModalToOuterMost,
}) {
  const closeButton = isDismissible ? (
    <button
      type="button"
      className="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    />
  ) : null;
  return (
    <ModalContainer
      modal_id={modal_id}
      size={size}
      isDismissible={isDismissible}
      isCentered={isCentered}
      onModalReady={onModalReady}
      moveModalToOuterMost={moveModalToOuterMost}
    >
      <div className="modal-header">
        {modal_title && (
          <h1 className="modal-title fs-5" id={modal_id}>
            {modal_title}
          </h1>
        )}
        {closeButton}
      </div>
      <div className="modal-body">{children}</div>
    </ModalContainer>
  );
}

Modal.defaultProps = {
  size: "xl",
  isDismissible: true,
  isCentered: false,
  modal_title: null,
  onModalReady: () => {
    // Do nothing
  },
  moveModalToOuterMost: true,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modal_title: PropTypes.string,
  modal_id: PropTypes.string.isRequired,
  size: PropTypes.string,
  isDismissible: PropTypes.bool,
  isCentered: PropTypes.bool,
  onModalReady: PropTypes.func,
  moveModalToOuterMost: PropTypes.bool,
};

export default Modal;
