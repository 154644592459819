import { City, Country, State } from "country-state-city";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import SelectInput from "modules/base/components/inputs/SelectInput";

function CountryRegionSelector({
  current_country,
  current_region,
  current_city,
  country_field_title,
  country_field_name,
  region_field_name,
  region_field_title,
  city_field_name,
  city_field_title,
  controlFunc,
  useCountryNameAsValue,
  displayRegionField,
  displayCityField,
  isCityMultiSelect,
  isCountryMultiSelect,
  isRegionMultiSelect,
  appendCountySuffixOnKenya,
}) {
  const [selectedCountry, setCountry] = useState(null);
  const [selectedCountryCode, setCountryCode] = useState(null);
  const [selectedRegion, setRegion] = useState(null);
  const [selectedCity, setCity] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountryCodes, setSelectedCountryCodes] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const countryOptions = Country.getAllCountries().map((country) => {
    const value = useCountryNameAsValue ? country.name : country.isoCode;
    return {
      value,
      label: country.name,
      isoCode: country.isoCode,
    };
  });

  useEffect(() => {
    if (current_country && !isCountryMultiSelect) {
      let current_country_code = current_country;
      if (useCountryNameAsValue) {
        current_country_code = (
          Country.getAllCountries().find(
            (country) => country.name === current_country
          ) || {}
        ).isoCode;
      }
      setCountry(current_country);
      setCountryCode(current_country_code);
    }
  }, [current_country]);

  useEffect(() => {
    if (current_country && isCountryMultiSelect) {
      let currentCountryCodes = current_country;
      if (useCountryNameAsValue) {
        currentCountryCodes = Country.getAllCountries()
          .filter((country) => current_country.includes(country.name))
          .map((country) => country.isoCode);
      }
      setSelectedCountries(current_country);
      setSelectedCountryCodes(currentCountryCodes);
    }
  }, [current_country]);

  useEffect(() => {
    if (current_region) {
      const current_region_code = (
        State.getStatesOfCountry(selectedCountryCode).find((region) => {
          if (appendCountySuffixOnKenya && region.countryCode === "KE") {
            return current_region === `${region.name} County`;
          }
          return region.name === current_region;
        }) || {}
      ).isoCode;
      setRegion(current_region_code);
    } else if (!current_region && current_city) {
      const currentRegionCode = (
        City.getCitiesOfCountry(selectedCountryCode).find(
          (city) => city.name === current_city
        ) ?? {}
      ).stateCode;
      setRegion(currentRegionCode);
    }
    if (current_city) {
      setCity(current_city);
    }
  }, [selectedCountryCode, current_region, current_city]);

  useEffect(() => {
    if (current_region && isRegionMultiSelect) {
      setSelectedRegions(
        State.getAllStates()
          .filter((region) => {
            if (appendCountySuffixOnKenya && region.countryCode === "KE") {
              return current_region.includes(`${region.name} County`);
            }
            return current_region.includes(region.name);
          })
          .map((region) => region.isoCode)
      );
    }
    if (current_city && isCityMultiSelect) {
      setSelectedCities(current_city);
    }
  }, [current_region, current_city]);

  let regionOptions = State.getStatesOfCountry(selectedCountryCode).map(
    (region) => {
      if (appendCountySuffixOnKenya && region.countryCode === "KE") {
        return {
          value: region.isoCode,
          label: `${region.name} County`,
        };
      }
      return { value: region.isoCode, label: region.name };
    }
  );
  if (isRegionMultiSelect) {
    regionOptions = State.getAllStates()
      .filter((state) => selectedCountryCodes.includes(state.countryCode))
      .map((region) => {
        if (appendCountySuffixOnKenya && region.countryCode === "KE") {
          return {
            value: region.isoCode,
            label: `${region.name} County`,
          };
        }
        return { value: region.isoCode, label: region.name };
      });
  }

  let cityOptions = City.getCitiesOfState(
    selectedCountryCode,
    selectedRegion
  ).map((city) => {
    return { value: city.name, label: city.name };
  });

  if (isCityMultiSelect) {
    cityOptions = City.getAllCities()
      .filter(
        (city) =>
          selectedRegions.includes(city.stateCode) &&
          selectedCountryCodes.includes(city.countryCode)
      )
      .map((city) => {
        return { value: city.name, label: city.name };
      });
  }

  function countryChange(selectedOption) {
    setCountry(selectedOption.value);
    setCountryCode(selectedOption.isoCode);
    setRegion(null);
    controlFunc(country_field_name, selectedOption.value);
  }

  function multiCountryChange(selectedOptions) {
    setSelectedCountries(selectedOptions.map((option) => option.isoCode));
    setSelectedCountryCodes(selectedOptions.map((option) => option.value));
    setSelectedRegions([]);
    controlFunc(country_field_name, selectedOptions);
  }

  function regionChange(selectedOption) {
    setRegion(selectedOption.value);
    setCity(null);
    controlFunc(region_field_name, selectedOption.label);
  }

  function multiRegionChange(selectedOptions) {
    setSelectedRegions(selectedOptions.map((option) => option.value));
    setSelectedCities([]);
    controlFunc(region_field_name, selectedOptions);
  }

  function cityChange(selectedOption) {
    setCity(selectedOption.value);
    controlFunc(city_field_name, selectedOption.value);
  }

  function multiCityChange(selectedOptions) {
    setSelectedCities(selectedOptions.map((option) => option.value));
    controlFunc(city_field_name, selectedOptions);
  }

  let handleCountryChange = useCallback(countryChange);
  let handleRegionChange = useCallback(regionChange);
  let handleCityChange = useCallback(cityChange);
  const handleMultiCountryChange = useCallback(multiCountryChange);
  const handleMultiRegionChange = useCallback(multiRegionChange);
  const handleMultiCityChange = useCallback(multiCityChange);
  let countryPredicate = (option) => option.value === selectedCountry;
  let regionPredicate = (option) => option.value === selectedRegion;
  let cityPredicate = (option) => option.value === selectedCity;
  if (isCountryMultiSelect) {
    countryPredicate = (option) => selectedCountries.includes(option.value);
    handleCountryChange = handleMultiCountryChange;
  }
  if (isRegionMultiSelect) {
    regionPredicate = (option) => selectedRegions.includes(option.value);
    handleRegionChange = handleMultiRegionChange;
  }
  if (isCityMultiSelect) {
    cityPredicate = (option) => selectedCities.includes(option.value);
    handleCityChange = handleMultiCityChange;
  }
  return (
    <>
      <SelectInput
        name={country_field_name}
        title={country_field_title}
        options={countryOptions}
        predicate={countryPredicate}
        onChange={handleCountryChange}
        isMulti={isCountryMultiSelect}
      />
      {displayRegionField && (
        <SelectInput
          name={region_field_name}
          title={region_field_title}
          options={regionOptions}
          predicate={regionPredicate}
          onChange={handleRegionChange}
          isMulti={isRegionMultiSelect}
        />
      )}
      {displayRegionField && displayCityField && (
        <SelectInput
          name={city_field_name}
          title={city_field_title}
          options={cityOptions}
          predicate={cityPredicate}
          onChange={handleCityChange}
          isMulti={isCityMultiSelect}
        />
      )}
    </>
  );
}

CountryRegionSelector.defaultProps = {
  current_country: null,
  current_region: null,
  current_city: null,
  country_field_name: "country",
  country_field_title: "Country",
  region_field_name: "region",
  region_field_title: "Region",
  city_field_name: "city",
  city_field_title: "City",
  useCountryNameAsValue: false,
  displayRegionField: true,
  displayCityField: true,
  isCountryMultiSelect: false,
  isRegionMultiSelect: false,
  isCityMultiSelect: false,
  appendCountySuffixOnKenya: false,
};

CountryRegionSelector.propTypes = {
  current_country: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  current_region: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  current_city: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  country_field_name: PropTypes.string,
  country_field_title: PropTypes.string,
  region_field_name: PropTypes.string,
  region_field_title: PropTypes.string,
  city_field_name: PropTypes.string,
  city_field_title: PropTypes.string,
  controlFunc: PropTypes.func.isRequired,
  useCountryNameAsValue: PropTypes.bool,
  displayRegionField: PropTypes.bool,
  displayCityField: PropTypes.bool,
  isCountryMultiSelect: PropTypes.bool,
  isRegionMultiSelect: PropTypes.bool,
  isCityMultiSelect: PropTypes.bool,
  appendCountySuffixOnKenya: PropTypes.bool,
};

export default CountryRegionSelector;
