import PropTypes from "prop-types";

function Button(props) {
  const { onClick, disabled, label } = props;
  return (
    <button
      type="button"
      className="border rounded me-1 mb-1 mb-md-0"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

function Pagination(props) {
  const {
    isDataPaginated,
    changePageSizeOptions,
    table,
    onChangePageSize,
    onClickStartPage,
    onClickPreviousButton,
    onClickNextButton,
    onClickSetLastPage,
    dataLength,
    hasPreviousPage,
    hasNextPage,
    totalPages,
    page,
    limit,
    generatePages,
  } = props;
  const previousButtonDisabled = table
    ? !table.getCanPreviousPage()
    : !hasPreviousPage;
  const nextButtonDisabled = table ? !table.getCanNextPage() : !hasNextPage;

  const pageIndex = table ? table.getState().pagination.pageIndex + 1 : page;
  const { pageSize } = table ? table.getState().pagination : limit;
  const minPageSize = 10;
  const pageSizes = [];

  if (dataLength < minPageSize) {
    pageSizes.push(dataLength);
  } else {
    for (let i = minPageSize; i <= dataLength; i += minPageSize) {
      pageSizes.push(i);
    }
  }
  const pageSizeMap = table ? pageSizes.map(changePageSizeOptions) : [];
  if (!isDataPaginated) {
    return null;
  }
  return (
    <div className="d-flex flex-column justify-content-start mb-2">
      <div className="col-12 col-md-2">
        <Button
          onClick={onClickStartPage}
          disabled={previousButtonDisabled}
          label="first"
        />
        <Button
          onClick={onClickPreviousButton}
          disabled={previousButtonDisabled}
          label="previous"
        />
        <Button
          onClick={onClickNextButton}
          disabled={nextButtonDisabled}
          label="next"
        />
        <Button
          onClick={onClickSetLastPage}
          disabled={nextButtonDisabled}
          label="last"
        />
      </div>
      <div className="col-md-2 d-md-flex justify-content-start align-items-center mt-2 d-none">
        <span className="fs-6 me-3">
          Page {pageIndex} of {table ? table.getPageCount() : totalPages}
        </span>
        {table ? (
          <select
            className="form-select w-auto"
            value={pageSize}
            onChange={onChangePageSize}
          >
            {pageSizeMap}
          </select>
        ) : (
          <div className="page-item">
            <div className="btn-group">
              <button
                className="btn btn-secondary m-1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Show {limit}
              </button>
              <ul className="dropdown-menu">{generatePages}</ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

Pagination.defaultProps = {
  totalPages: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  page: 0,
  limit: 0,
  table: null,
};

Pagination.propTypes = {
  isDataPaginated: PropTypes.bool.isRequired,
  changePageSizeOptions: PropTypes.func.isRequired,
  table: PropTypes.instanceOf(Object),
  onChangePageSize: PropTypes.func.isRequired,
  onClickStartPage: PropTypes.func.isRequired,
  onClickPreviousButton: PropTypes.func.isRequired,
  onClickNextButton: PropTypes.func.isRequired,
  onClickSetLastPage: PropTypes.func.isRequired,
  dataLength: PropTypes.number.isRequired,
  generatePages: PropTypes.arrayOf(PropTypes.element).isRequired,
  totalPages: PropTypes.number,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
};

export default Pagination;
