import { BaseModel } from "modules/base/models/Base";

export class Tokens extends BaseModel {
  access_token: string;

  refresh_token: string;

  // fromObject(obj) {
  //   console.log("fromObject", obj);
  //   return super.fromObject(obj);
  // }

  constructor(access_token: string, refresh_token: string) {
    super();
    this.access_token = access_token;
    this.refresh_token = refresh_token;
  }
}
