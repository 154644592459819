import { Modal as BootstrapModal } from "bootstrap";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import AppsApiActions from "modules/apps/ApiActions";
import TextInput from "modules/base/components/inputs/TextInput";
import Form from "modules/base/components/Form";

function AppDisplayNameForm({ appDisplayName, appId }) {
  const appApiActions = new AppsApiActions();
  const [displayName, setDisplayName] = useState(appDisplayName);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const changeNameModal = BootstrapModal.getOrCreateInstance(
      document.getElementById(`change-name-${appId}`),
    );
    changeNameModal.show();
  }, [appId]);

  const handleCustomNameInputChange = useCallback((e) => {
    setDisplayName(e.target.value);
  }, []);

  const handleNameSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (displayName.trim() === "") {
        return true;
      }

      setSuccess(null);
      setError(null);

      appApiActions.changeManagedAppName(
        appId,
        displayName,
        () => {
          setSuccess(`The app has been renamed to ${displayName}`);
          setError(null);
        },
        () => {
          setError({
            message:
              "Something went wrong while renaming the app. Please try again",
          });
          setSuccess(null);
        },
      );

      return null;
    },
    [displayName, appId],
  );

  return (
    <Modal
      modal_title="Change App Name"
      modal_id={`change-name-${appId}`}
      size="lg"
      isDismissible
    >
      <Form handleSubmit={handleNameSubmit} items_flow="vertical" padding="p-1">
        <div className="mb-4">
          <TextInput
            inputType="text"
            className="form-control m-4"
            id="displayNameInput"
            content={displayName}
            controlFunc={handleCustomNameInputChange}
            placeholder="Enter new app display name"
          />
        </div>
      </Form>
      <NetworkMessageDisplay error={error} success={success} />
    </Modal>
  );
}

AppDisplayNameForm.propTypes = {
  appDisplayName: PropTypes.string.isRequired,
  appId: PropTypes.number.isRequired,
};

export default AppDisplayNameForm;
