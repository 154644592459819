import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AppsApiActions from "modules/apps/ApiActions";
import { FULL_PATH_LAUNCH_AVAILABLE_APP } from "modules/apps/Contants";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { serverGeneratePath } from "modules/base/utilities/Navigation";

function AvailableAppsList() {
  const appsApiActions = new AppsApiActions();
  const { available_apps, error, isLoading } =
    appsApiActions.getAvailableApps();
  const navigate = useNavigate();
  const onSelect = useCallback((e) => {
    const id = e.value;
    const launch_app_path = serverGeneratePath(
      `${FULL_PATH_LAUNCH_AVAILABLE_APP}`,
      {
        id,
      },
    );
    navigate(launch_app_path);
  });

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  const availableApps = available_apps.map((app) => {
    const Icon = <img src={app.icon} alt="icon" width={50} height={50} />;
    return (
      <SelectOptionCard
        key={app.id}
        value={app.id}
        label={app.name}
        subtitle=""
        onSelect={onSelect}
        icon={Icon}
        icon_position="top"
        description={
          <div className="row">
            <span className="text-muted">{app.description}</span>
          </div>
        }
        section_name="available-apps"
      />
    );
  });
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <div className="card">
        <div className="card-header">Launch Instant Apps</div>
        <div className="card-body p-0 p-md-3">
          <div className="row row-cols-md-4 row-cols-1 gy-md-3 gy-1">
            {availableApps}
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailableAppsList;
