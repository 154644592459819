import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

function CheckboxInput(props) {
  const {
    title,
    name,
    handleChange,
    checked,
    showLabel,
    showTooltip,
    tooltip_content,
    tooltip_variant,
    tooltip_place,
  } = props;
  const id = uuidv4();
  const label = showLabel ? (
    <label htmlFor={id} className="form-check-label">
      {title}
    </label>
  ) : null;
  const tooltip = showTooltip ? (
    <Tooltip id={id}>{tooltip_content}</Tooltip>
  ) : null;
  return (
    <>
      {tooltip}
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          name={name}
          id={id}
          onChange={handleChange}
          checked={checked}
          data-tooltip-id={id}
          data-tooltip-variant={tooltip_variant}
          data-tooltip-place={tooltip_place}
          data-tooltip-content={tooltip_content}
        />
        {label}
      </div>
    </>
  );
}

CheckboxInput.defaultProps = {
  showLabel: true,
  title: null,
  showTooltip: false,
  tooltip_content: null,
  tooltip_variant: "dark",
  tooltip_place: "right",
};

CheckboxInput.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltip_content: PropTypes.string,
  tooltip_variant: PropTypes.string,
  tooltip_place: PropTypes.string,
};

export default CheckboxInput;
