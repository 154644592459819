/**
 * Base model class
 */
export class BaseModel extends Object {
  /**
   * Create model instance from array
   * @param arr
   */
  fromArray(arr = []) {
    const attributes: string[] = Object.keys(this);
    return arr.map((item) => {
      const model = new this.constructor();
      attributes.forEach((attribute) => {
        model[attribute] = item[attribute];
      });
      return model;
    });
  }
}
