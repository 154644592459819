import React from "react";
import ContentContainer from "../../core/layouts/ContentContainer";
import MainContainer from "../../core/layouts/MainContainer";
import SideBar from "../../core/layouts/SideBar";
import VirtualMachineDeploy from "../components/VirtualMachineDeploy";
import TopBar from "modules/core/layouts/TopBar";

/**
 * @name DeployVirtualMachine
 * @description This is the main component for the Deploy Server page.
 * @returns {JSX.Element}
 * @constructor
 */
function DeployVirtualMachine() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <VirtualMachineDeploy />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default DeployVirtualMachine;
