import React from "react";
import { Route, Routes } from "react-router-dom";
import { PATH_LOGIN } from "./Constants";
import Login from "./layouts/Login";

/**
 * Account Routes
 * @return {JSX.Element}
 * @constructor
 */
function AccountRoutes() {
  return (
    <Routes>
      <Route path={PATH_LOGIN} element={<Login />} />
    </Routes>
  );
}

export default AccountRoutes;
