import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GithubLoginButton } from "react-social-login-buttons";
import { IResolveParams, LoginSocialGithub } from "reactjs-social-login";
import { useAuth } from "../../../AuthProvider";
import NetworkActivityIndicator from "../../base/components/NetworkActivityIndicator";
import {
  PATH_LIST_VIRTUAL_MACHINES,
  PATH_VIRTUAL_MACHINES,
} from "../../servers/Constants";
import { AuthenticationActions } from "../Actions";
import { AuthenticationApi } from "../ApiActions";
import {
  ENABLE_GITHUB_LOGIN,
  GITHUB_APP_ID,
  GITHUB_APP_SECRET,
} from "../Constants";
import { Tokens } from "../Models";

/**
 * Github Login
 * @returns {JSX.Element}
 */
function GithubLogin() {
  const REDIRECT_URI = window.location.href;
  const authenticationApi = new AuthenticationApi();
  const authenticationActions = new AuthenticationActions();
  const [is_authenticating, setAuthenticating] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const onLoginStart = () => {
    setAuthenticating(true);
  };

  const handleGithubLogin = (profile) => {
    const { access_token: github_access_token } = profile;
    authenticationApi.loginWithGithub(
      github_access_token,
      (response_data: any) => {
        const { access_token, refresh_token } = response_data;
        const tokens = new Tokens(access_token, refresh_token);
        authenticationActions.storeAuthenticationTokens(tokens).then(() => {
          auth.login(tokens);
          setAuthenticating(false);
          navigate(`${PATH_VIRTUAL_MACHINES}/${PATH_LIST_VIRTUAL_MACHINES}`);
        });
      },
      (error: any) => {
        setAuthenticating(false);
        console.log("error", error);
      }
    );
  };
  const github_login_display = ENABLE_GITHUB_LOGIN ? "block" : "none";
  let githubLoginButton = (
    <LoginSocialGithub
      client_id={GITHUB_APP_ID}
      client_secret={GITHUB_APP_SECRET}
      redirect_uri={REDIRECT_URI}
      onLoginStart={onLoginStart}
      scope="user:email"
      onResolve={({ data }: IResolveParams) => {
        handleGithubLogin(data);
      }}
      onReject={(err: any) => {
        console.log(err);
      }}
    >
      <GithubLoginButton
        className={`fs-5 text-nowrap d-${github_login_display}`}
      >
        Continue with Github
      </GithubLoginButton>
    </LoginSocialGithub>
  );
  if (is_authenticating) {
    githubLoginButton = <NetworkActivityIndicator />;
  }
  return (
    <div className="col d-flex justify-content-center">{githubLoginButton}</div>
  );
}

export default GithubLogin;
