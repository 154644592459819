import React from "react";
import GithubLogin from "modules/account/components/GithubLogin";
import GoogleLogin from "modules/account/components/GoogleLogin";
import LoginContainer from "modules/account/layouts/LoginContainer";
import { ReactComponent as Logo } from "modules/core/assets/cloudpap-logo.svg";
import FocusItems from "modules/marketing/components/FocusItems";

/**
 * Login page
 * @returns
 */
function Login() {
  return (
    <>
      <FocusItems />
      <LoginContainer>
        <form>
          <Logo className="text-warning" width="200" />
          <div className="row row-cols-1 gy-3 d-flex justify-content-center">
            <GoogleLogin />
            <GithubLogin />
          </div>
        </form>
      </LoginContainer>
    </>
  );
}

export default Login;
