import {
  BillingCard,
  BillingCoupon,
  BillingCustomer,
  BillingInvoice,
  BillingPaymentMethod,
  BillingTransaction,
  BillingWallet,
} from "modules/base/models/Billing";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import {
  URL_BILLING_APPLIED_COUPONS,
  URL_BILLING_APPLY_COUPON,
  URL_BILLING_CARD,
  URL_BILLING_CARDS,
  URL_BILLING_CARD_DEFAULT,
  URL_BILLING_CUSTOMER,
  URL_BILLING_INVOICES,
  URL_BILLING_PAYMENT_METHOD,
  URL_BILLING_PAYMENT_METHODS,
  URL_BILLING_TRANSACTIONS,
  URL_BILLING_WALLET,
} from "modules/billing/Contants";
import PrivateApi from "modules/core/utilities/PrivateApi";

export default class BillingApiActions extends PrivateApi {
  /**
   * Get billing customer
   * @param id
   * @returns {{isLoading: boolean, error: any, customer: BillingCustomer}}
   */
  getBillingCustomer(id: string) {
    const url = serverGeneratePath(URL_BILLING_CUSTOMER, { id });
    const { data, error, isLoading } = this.getAPIRequest(url);
    const customer = new BillingCustomer(data);
    return { customer, error, isLoading };
  }

  /**
   * Update billing customer
   * @param id
   * @param payload
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, customer: BillingCustomer}}
   */
  updateBillingCustomer(
    id: string,
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_BILLING_CUSTOMER, { id });
    this.postAPIRequest(url, payload, successCallback, errorCallback, "PUT");
  }

  /**
   * Get billing invoices
   * @returns {{isLoading: boolean, error: any, invoices: BillingInvoice[]}}
   */
  getBillingInvoices() {
    const { data, error, isLoading } = this.getAPIRequest(URL_BILLING_INVOICES);
    const billingInvoice = new BillingInvoice();
    const invoices: BillingInvoice[] = billingInvoice.fromArray(data);
    return { invoices, error, isLoading };
  }

  /**
   * Get billing transactions
   * @returns {{isLoading: boolean, error: any, transactions: BillingTransaction[]}}
   */
  getBillingTransactions() {
    const { data, error, isLoading } = this.getAPIRequest(
      URL_BILLING_TRANSACTIONS
    );
    const billingTransaction = new BillingTransaction();
    const transactions: BillingTransaction[] =
      billingTransaction.fromArray(data);
    return { transactions, error, isLoading };
  }

  /**
   * Get billing payment methods
   * @returns {{isLoading: boolean, error: any, paymentMethods: BillingPaymentMethod[]}}
   */
  getBillingPaymentMethods() {
    const { data, error, isLoading } = this.getAPIRequest(
      URL_BILLING_PAYMENT_METHODS
    );
    const billingPaymentMethod = new BillingPaymentMethod();
    const payment_methods: BillingPaymentMethod[] =
      billingPaymentMethod.fromArray(data);
    return { payment_methods, error, isLoading };
  }

  /**
   * Create billing payment method
   * @param payload
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, paymentMethod: BillingPaymentMethod}}
   */
  createBillingPaymentMethod(
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    this.postAPIRequest(
      URL_BILLING_PAYMENT_METHODS,
      payload,
      successCallback,
      errorCallback
    );
  }

  /**
   * Delete billing payment method
   * @param id
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, paymentMethod: BillingPaymentMethod}}
   */
  deleteBillingPaymentMethod(
    id: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_BILLING_PAYMENT_METHOD, { id });
    this.deleteAPIRequest(url, successCallback, errorCallback);
  }

  /**
   * Get billing cards
   * @returns {{isLoading: boolean, error: any, cards: BillingCard[]}}
   */
  getBillingCards() {
    const { data, error, isLoading } = this.getAPIRequest(URL_BILLING_CARDS);
    const billingCard = new BillingCard();
    const cards: BillingCard[] = billingCard.fromArray(data);
    return { cards, error, isLoading };
  }

  /**
   * Create billing card
   * @param payload
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, card: BillingCard}}
   */
  createBillingCard(
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    this.postAPIRequest(
      URL_BILLING_CARDS,
      payload,
      successCallback,
      errorCallback,
      "POST",
      [URL_BILLING_CARDS, serverGeneratePath(URL_BILLING_CUSTOMER, { id: "i" })]
    );
  }

  /**
   * Delete billing card
   * @param id
   * @param successCallback
   * @param errorCallback
   * @returns {{isLoading: boolean, error: any, card: BillingCard}}
   */
  deleteBillingCard(
    id: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_BILLING_CARD, { id });
    this.deleteAPIRequest(url, successCallback, errorCallback, [
      URL_BILLING_CARDS,
      URL_BILLING_CUSTOMER,
    ]);
  }

  /**
   * Set default billing card
   * @param id
   * @param successCallback
   * @param errorCallback
   */
  setDefaultBillingCard(
    id: string,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_BILLING_CARD_DEFAULT, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback, "PATCH", [
      URL_BILLING_CARDS,
      serverGeneratePath(URL_BILLING_CUSTOMER, { id: "i" }),
    ]);
  }

  /**
   * Apply coupon
   * @param id
   * @param payload
   * @param successCallback
   * @param errorCallback
   */
  applyCoupon(
    id: string,
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_BILLING_APPLY_COUPON, { id });
    this.postAPIRequest(url, payload, successCallback, errorCallback, "POST", [
      serverGeneratePath(URL_BILLING_APPLIED_COUPONS, { id }),
    ]);
  }

  /**
   * Get applied coupons
   * @param id
   * @returns {{isLoading: boolean, error: any, coupon: BillingCoupon}}
   */
  getAppliedCoupons(id: string) {
    const url = serverGeneratePath(URL_BILLING_APPLIED_COUPONS, { id });
    const { data, error, isLoading } = this.getAPIRequest(url);
    const billingCoupon = new BillingCoupon();
    const coupons: BillingCoupon = billingCoupon.fromArray(data);
    return { coupons, error, isLoading };
  }

  /**
   * Get billing wallet
   * @param id
   * @returns {{isLoading: boolean, error: any, wallet: BillingWallet}}
   */
  getBillingWallet(id: string) {
    const url = serverGeneratePath(URL_BILLING_WALLET, { id });
    const { data, error, isLoading } = this.getAPIRequest(url);
    const wallet = new BillingWallet(data);
    return { wallet, error, isLoading };
  }
}
