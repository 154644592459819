// eslint-disable-next-line max-classes-per-file
import { BaseModel } from "modules/base/models/Base";

/**
 * BillingCustomer model
 */
export class BillingCustomer extends BaseModel {
  id: number;

  name: string;

  email: string;

  address_line1: string;

  address_line2: string;

  zip_code: string;

  city: string;

  state: string;

  country: string;

  currency: string;

  phone_number: string;

  has_reached_max_cards_allowed: boolean;

  has_minimum_cards_allowed: boolean;

  minimum_cards_allowed: number;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.address_line1 = data.address_line1;
    this.address_line2 = data.address_line2;
    this.zip_code = data.zip_code;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;
    this.currency = data.currency;
    this.phone_number = data.phone_number;
    this.has_reached_max_cards_allowed = data.has_reached_max_cards_allowed;
    this.has_minimum_cards_allowed = data.has_minimum_cards_allowed;
    this.minimum_cards_allowed = data.minimum_cards_allowed;
  }
}

/**
 * BillingInvoice model
 */
export class BillingInvoice extends BaseModel {
  id: number;

  number: string;

  due_date: string;

  issue_date: string;

  paid_date: string;

  cancel_date: string;

  currency: string;

  transaction_currency: string;

  total_in_transaction_currency: number;

  state: string;

  constructor(data: Object = {}) {
    super();
    this.id = data.id;
    this.number = data.number;
    this.due_date = data.due_date;
    this.issue_date = data.issue_date;
    this.paid_date = data.paid_date;
    this.cancel_date = data.cancel_date;
    this.currency = data.currency;
    this.transaction_currency = data.transaction_currency;
    this.total_in_transaction_currency = data.total_in_transaction_currency;
    this.state = data.state;
  }
}

export class BillingPaymentMethod extends BaseModel {
  id: number;

  payment_processor_name: string;

  payment_processor: string;

  added_at: string;

  verified: boolean;

  cancelled: boolean;

  valid_until: string;

  display_info: string;

  card_details: {
    brand: string,
    country: string,
    cvc_check: string,
    exp_month: number,
    exp_year: number,
    funding: string,
    last4: string,
  };

  constructor(data: Object = {}) {
    super();
    this.id = data.id;
    this.payment_processor_name = data.payment_processor_name;
    this.payment_processor = data.payment_processor;
    this.added_at = data.added_at;
    this.verified = data.verified;
    this.cancelled = data.cancelled;
    this.valid_until = data.valid_until;
    this.display_info = data.display_info;
    this.card_details = data.card_details;
  }
}

export class BillingCard extends BaseModel {
  id: number;

  display_name: string;

  brand: string;

  country: string;

  cvc_check: string;

  exp_month: number;

  exp_year: number;

  funding: string;

  last4: string;

  default: boolean;

  constructor(data: Object = {}) {
    super();
    this.id = data.id;
    this.display_name = data.display_name;
    this.brand = data.brand;
    this.country = data.country;
    this.cvc_check = data.cvc_check;
    this.exp_month = data.exp_month;
    this.exp_year = data.exp_year;
    this.funding = data.funding;
    this.last4 = data.last4;
    this.default = data.default;
  }
}

export class BillingTransaction extends BaseModel {
  id: number;

  amount: number;

  currency: string;

  state: string;

  valid_until: string;

  updated_at: string;

  created_at: string;

  fail_code: string;

  refund_code: string;

  cancel_code: string;

  invoice_number: string;

  invoice_due_date: string;

  invoice_issue_date: string;

  invoice_paid_date: string;

  constructor(data: Object = {}) {
    super();
    this.id = data.id;
    this.amount = data.amount;
    this.currency = data.currency;
    this.state = data.state;
    this.valid_until = data.valid_until;
    this.updated_at = data.updated_at;
    this.created_at = data.created_at;
    this.fail_code = data.fail_code;
    this.refund_code = data.refund_code;
    this.cancel_code = data.cancel_code;
    this.invoice_number = data.invoice_number;
    this.invoice_due_date = data.invoice_due_date;
    this.invoice_issue_date = data.invoice_issue_date;
    this.invoice_paid_date = data.invoice_paid_date;
  }
}

export class BillingCoupon extends BaseModel {
  coupon_code: string;

  amount_cents: number;

  amount_cents_remaining: number;

  amount_currency: string;

  expiration_date: string;

  frequency: string;

  frequency_duration: number;

  percentage_rate: number;

  constructor(data: Object = {}) {
    super();
    this.coupon_code = data.coupon_code;
    this.amount_cents = data.amount_cents;
    this.amount_cents_remaining = data.amount_cents_remaining;
    this.amount_currency = data.amount_currency;
    this.expiration_date = data.expiration_date;
    this.frequency = data.frequency;
    this.frequency_duration = data.frequency_duration;
    this.percentage_rate = data.percentage_rate;
  }
}

export class BillingWallet extends BaseModel {
  balance_cents: number;

  consumed_credits: number;

  created_at: string;

  credits_balance: number;

  currency: string;

  expiration_at: string;

  constructor(data: Object = {}) {
    super();
    this.balance_cents = data.balance_cents;
    this.consumed_credits = data.consumed_credits;
    this.created_at = data.created_at;
    this.credits_balance = data.credits_balance ?? 0;
    this.currency = data.currency ?? "USD";
    this.expiration_at = data.expiration_at;
  }
}

export class BillingWalletTransaction extends BaseModel {
  amount: number;

  created_at: string;

  credit_amount: number;

  settled_at: string;

  status: string;

  transaction_type: string;

  constructor(data: Object = {}) {
    super();
    this.amount = data.amount;
    this.created_at = data.created_at;
    this.credit_amount = data.credit_amount;
    this.settled_at = data.settled_at;
    this.status = data.status;
    this.transaction_type = data.transaction_type;
  }
}

/**
 * BillingAddon model
 *
 * This model is used to store the details of billing addons. It inherits from the BaseModel.
 *
 * @property {number} id - The unique identifier of the billing addon.
 * @property {string} name - The name of the billing addon.
 * @property {string} description - The description of the billing addon.
 * @property {number} price - The price of the billing addon.
 * @property {string} currency - The currency of the billing addon.
 *
 * @param {Object} data - The data used to initialize the BillingAddon instance.
 * @param {number} data.id - The unique identifier of the billing addon.
 * @param {string} data.name - The name of the billing addon.
 * @param {string} data.description - The description of the billing addon.
 * @param {number} data.price - The price of the billing addon.
 * @param {string} data.currency - The currency of the billing addon.
 */
export class BillingAddon extends BaseModel {
  id: number;

  name: string;

  description: string;

  price: number;

  currency: string;

  constructor(data: Object = {}) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.price = data.price;
    this.currency = data.currency;
  }
}

export class BillingAddonPurchase extends BaseModel {
  id: number;

  addon: BillingAddon;

  quantity: number;

  payment_status: string;

  purchase_time: string;

  payment_time: string;

  constructor(data: Object = {}) {
    super();
    this.id = data.id;
    this.addon = new BillingAddon(data.addon);
    this.quantity = data.quantity;
    this.payment_status = data.payment_status;
    this.purchase_time = data.purchase_time;
    this.payment_time = data.payment_time;
  }
}
