import PropTypes from "prop-types";
import React from "react";
import AppsApiActions from "modules/apps/ApiActions";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ContentCard from "modules/core/layouts/ContentCard";

function AvailableAppVersions(props) {
  const { app_id, onSelect } = props;
  const appsApiActions = new AppsApiActions();
  const { versions, error, isLoading } =
    appsApiActions.getAvailableAppVersions(app_id);
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  const Versions = versions.map((version) => (
    <SelectOptionCard
      key={version.id}
      value={version.id}
      label={version.version}
      subtitle=""
      onSelect={onSelect}
      icon_position="top"
      description=""
      section_name="version"
    />
  ));
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <ContentCard
        header={
          <span>
            <i className="bi bi-git  text-purple" /> Version
          </span>
        }
      >
        <div className="row row-cols-md-4 row-cols-1 gy-md-3 gy-1">
          {Versions}
        </div>
      </ContentCard>
    </>
  );
}

AvailableAppVersions.propTypes = {
  app_id: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AvailableAppVersions;
