import React from "react";
import AvailableAppLaunch from "modules/apps/components/AvailableAppLaunch";
import ContentContainer from "modules/core/layouts/ContentContainer";
import MainContainer from "modules/core/layouts/MainContainer";
import SideBar from "modules/core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";

function LaunchAvailableApp() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <AvailableAppLaunch />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default LaunchAvailableApp;
