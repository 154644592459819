import { Modal as BootstrapModal } from "bootstrap";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";
import OperatingSystemSelect from "modules/servers/components/OperatingSystemSelect";
import { VirtualMachine } from "modules/servers/Models";

function OperatingSystemManage(props) {
  const { vm, resource_type } = props;
  const serversApiActions = new ServersApiActions();
  const { locations } = serversApiActions.getVirtualMachineLocations();
  const virtual_machine_location = locations.find(
    (location) => location.id === vm.location,
  );
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  let changeOSButton = <div />;
  const onChangeOSClick = useCallback((e) => {
    e.preventDefault();
    const changeOSModal = BootstrapModal.getOrCreateInstance(
      document.getElementById(`change-os-${vm.id}`),
    );
    changeOSModal.show();
  });

  const changeOS = useCallback((e, successCallback, errorCallback) => {
    const selected_image_id = Number(e.value);
    serversApiActions.changeVirtualMachineImage(
      vm.id,
      selected_image_id,
      () => {
        setSuccess("Plan upgraded successfully");
        setError(null);
        successCallback();
      },
      (error_response) => {
        setError({
          message: error_response.message,
        });
        setSuccess(null);
        errorCallback("Error upgrading plan", error_response.message);
      },
    );
  });

  if (resource_type === "vm") {
    changeOSButton = (
      <button
        type="button"
        className="btn btn-sm btn-outline-purple mt-2 ms-md-2 ms-0"
        onClick={onChangeOSClick}
      >
        <i className="bi bi-disc" /> Change OS
      </button>
    );
  }

  return (
    <>
      {changeOSButton}
      <Modal
        modal_title="Change Operating System"
        modal_id={`change-os-${vm.id}`}
        size="lg"
        isDismissible
      >
        <OperatingSystemSelect
          onSelect={changeOS}
          selected_location={virtual_machine_location}
          no_of_md_cols={2}
          selected_image={vm.image}
          showConfirm
          confirm={{
            title: "Change OS?",
            message:
              "This action will change the operating system of the virtual machine and " +
              "delete all data on the virtual machine. Are you sure you want to continue?",
            confirm_button_label: "Change OS",
            action_done_title: "OS changed",
            action_done_message: "The OS has been changed.",
            confirm_button_color: "danger",
          }}
        />
        <NetworkMessageDisplay error={error} success={success} />
      </Modal>
    </>
  );
}

OperatingSystemManage.propTypes = {
  vm: PropTypes.instanceOf(VirtualMachine).isRequired,
  resource_type: PropTypes.oneOf(["app", "vm"]).isRequired,
};

export default OperatingSystemManage;
