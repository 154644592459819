import React from "react";
import { Route, Routes } from "react-router-dom";
import { PATH_VIEW_BILLING } from "./Contants";
import ViewBilling from "./layouts/ViewBilling";

function BillingRoutes() {
  return (
    <Routes>
      <Route path={PATH_VIEW_BILLING} element={<ViewBilling />} />
    </Routes>
  );
}

export default BillingRoutes;
