import PropTypes from "prop-types";
import React, { useCallback } from "react";
import BaseClaimCoupon from "modules/base/components/billing/BaseClaimCoupon";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import BillingApiActions from "modules/billing/ApiActions";
import ClaimedCoupons from "modules/billing/components/ClaimedCoupons";

function ClaimCoupon(props) {
  const { selected_billing_plan_id, selected_backup_billing_plan_id } = props;
  const [showCouponForm, setShowCouponForm] = React.useState(false);
  const [state, setState] = React.useState({
    coupon_code: "",
  });
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const billingApiActions = new BillingApiActions();

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  });

  const handleCoupon = useCallback(() => {
    setShowCouponForm(true);
  });

  const handleClaimCoupon = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      coupon_code: state.coupon_code,
      billing_plan: selected_billing_plan_id,
    };
    if (selected_backup_billing_plan_id) {
      payload.backup_billing_plan = selected_backup_billing_plan_id;
    }
    billingApiActions.applyCoupon(
      "i",
      payload,
      () => {
        setIsSubmitting(false);
        setShowCouponForm(false);
        setSuccess({
          message: "The coupon has been applied to your account.",
        });
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
      },
    );
  });

  return (
    <div className="mb-3">
      <NetworkMessageDisplay error={error} success={success} />
      <BaseClaimCoupon
        handleClaimCoupon={handleClaimCoupon}
        handleInputChange={handleInputChange}
        state={state}
        showCouponForm={showCouponForm}
        handleCoupon={handleCoupon}
        isSubmitting={isSubmitting}
        ClaimedCouponsComponent={ClaimedCoupons}
      />
    </div>
  );
}

ClaimCoupon.defaultProps = {
  selected_billing_plan_id: null,
  selected_backup_billing_plan_id: null,
};

ClaimCoupon.propTypes = {
  selected_billing_plan_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  selected_backup_billing_plan_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ClaimCoupon;
