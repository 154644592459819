import _ from "lodash";
import userSWR, { mutate } from "swr";

export default class NetworkRequests {
  headers = null;

  format_json_error = true;

  /**
   * Process the error response
   * @param response
   * @returns {*}
   */
  processError(response) {
    if (response.status === 401) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      window.dispatchEvent(new Event("localStorageChange"));
    }
    return response.json().then((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else if (error.detail) {
        throw new Error(error.detail);
      } else if (error.non_field_errors) {
        throw new Error(error.non_field_errors);
      } else if (Array.isArray(error)) {
        if (this.format_json_error) {
          let error_message = "";
          error.forEach((error_item) => {
            error_message += `${error_item} `;
          });
          throw new Error(error_message);
        } else {
          throw new Error(error);
        }
      } else if (typeof error === "object") {
        if (this.format_json_error) {
          let error_message = "";
          Object.keys(error).forEach((key) => {
            let error_key = key.replace("_", " ");
            error_key = _.capitalize(error_key);
            error_message += `${error_key}: ${error[key]} `;
          });
          throw new Error(error_message);
        } else {
          throw new Error(error);
        }
      } else {
        throw new Error(response.statusText);
      }
    });
  }

  /**
   * Make a POST request with JSON data
   * @param {string} url - The URL to send the request to
   * @param {object} data - The data to send as JSON
   * @param {string} method - The HTTP method to use (default: "POST")
   * @param {function} successCallback - The function to call on successful response
   * @param {function} errorCallback - The function to call on error
   * @param {array} revalidate_urls - The URLs to revalidate after successful response
   */
  postAPIRequest(
    url,
    data,
    successCallback,
    errorCallback,
    method = "POST",
    revalidate_urls = [],
  ) {
    const request = {
      method,
      headers: this.headers,
      body: JSON.stringify(data),
    };

    fetch(url, request)
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            return {};
          }
          return response.json();
        }
        if (response.status >= 500) {
          throw new Error("That's on us. Please try again.");
        }
        return this.processError(response);
      })
      .then((response_data) => {
        mutate(url);
        revalidate_urls.forEach((revalidate_url) => {
          mutate(revalidate_url);
        });
        successCallback(response_data);
      })
      .catch((error) => {
        errorCallback(error);
      });
  }

  /**
   * Make a GET request
   * @param {string} url - The URL to send the request to
   * @param {number} refreshInterval - The interval in milliseconds to refresh the data
   */
  getAPIRequest(url, refreshInterval = 0) {
    const { headers } = this;
    return userSWR(
      url,
      (url_) => {
        return fetch(url_, {
          headers,
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
          if (response.status >= 500) {
            throw new Error("That's on us. Please try again.");
          }
          return this.processError(response);
        });
      },
      { refreshInterval },
    );
  }

  /**
   * Make a DELETE request
   * @param {string} url - The URL to send the request to
   * @param {function} successCallback - The function to call on successful response
   * @param {function} errorCallback - The function to call on error
   * @param {array} revalidate_urls - The URLs to revalidate after successful response
   */
  deleteAPIRequest(url, successCallback, errorCallback, revalidate_urls = []) {
    fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            return {};
          }
          return response.json();
        }
        if (response.status >= 500) {
          throw new Error("That's on us. Please try again.");
        }
        return this.processError(response);
      })
      .then((response_data) => {
        mutate(url);
        revalidate_urls.forEach((revalidate_url) => {
          mutate(revalidate_url);
        });
        successCallback(response_data);
      })
      .catch((error) => {
        errorCallback(error);
      });
  }
}
