import PropTypes from "prop-types";
import React from "react";

function SectionTitle(props) {
  const { title } = props;
  return <p className="h3">{title}</p>;
}

SectionTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default SectionTitle;
