import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import BaseDebitCreditCardForm from "modules/base/components/billing/BaseDebitCreditCardForm";
import { closeModal } from "modules/base/utilities/Actions";
import BillingApiActions from "modules/billing/ApiActions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

/**
 * @description Form for adding a debit/credit card
 * @returns {JSX.Element}
 * @constructor
 */
function DebitCreditCardForm(props) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const billingApiActions = new BillingApiActions();
  const { isDismissible } = props;

  function submitForm(card_details, cardElement) {
    billingApiActions.createBillingCard(
      card_details,
      () => {
        setIsSubmitting(false);
        // reset stripe form
        cardElement.clear();
        setSuccess({
          message: "Card added successfully",
        });
        closeModal("add-card");
      },
      (error_response) => {
        setIsSubmitting(false);
        setError({
          message: error_response.message,
        });
      },
    );
  }
  const handleSubmit = useCallback(submitForm);

  return (
    <BaseDebitCreditCardForm
      isDismissible={isDismissible}
      error={error}
      success={success}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      setIsSubmitting={setIsSubmitting}
      setError={setError}
      setSuccess={setSuccess}
    />
  );
}

DebitCreditCardForm.propTypes = {
  isDismissible: PropTypes.bool.isRequired,
};

/**
 * @description Wrapper for debit/credit card form
 * @returns {JSX.Element}
 * @constructor
 */
function DebitCreditCardFormWrapper(props) {
  const { isDismissible } = props;
  return (
    <Elements stripe={stripePromise}>
      <DebitCreditCardForm isDismissible={isDismissible} />
    </Elements>
  );
}

DebitCreditCardFormWrapper.defaultProps = {
  isDismissible: true,
};

DebitCreditCardFormWrapper.propTypes = {
  isDismissible: PropTypes.bool,
};

export default DebitCreditCardFormWrapper;
