import PropTypes from "prop-types";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import ActionInput from "modules/base/components/ActionInput";

function ActionsDropdown(props) {
  const {
    label,
    actionsList,
    subject_id,
    removeTextWhiteClass = false,
  } = props;
  const buttonClasses = `btn btn-auto-metal-saurus btn-sm dropdown-toggle ${
    removeTextWhiteClass ? "btn-outline-gray-900" : "text-white"
  }`;
  const actions = actionsList.map((action) => {
    const { label: action_label, icon, onClick, showConfirm, confirm } = action;
    return (
      <li key={uuidv4()}>
        <ActionInput
          label={action_label}
          icon={icon}
          onClick={onClick}
          showConfirm={showConfirm}
          confirm={confirm}
          subject_id={subject_id}
          button_classes="dropdown-item"
        />
      </li>
    );
  });
  return (
    <div className="dropdown">
      <button
        className={buttonClasses}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {label}
      </button>
      <ul className="dropdown-menu">{actions}</ul>
    </div>
  );
}

ActionsDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      showConfirm: PropTypes.bool,
      confirm: PropTypes.shape({
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        confirm_button_label: PropTypes.string.isRequired,
        action_done_title: PropTypes.string.isRequired,
        action_done_message: PropTypes.string.isRequired,
        confirm_button_color: PropTypes.string,
      }),
    })
  ).isRequired,
  subject_id: PropTypes.number.isRequired,
  removeTextWhiteClass: PropTypes.bool.isRequired,
};

export default ActionsDropdown;
