import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

function DisplayIcon(props) {
  const { icon_name, size, className, color } = props;
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        const { default: namedImport } = await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!modules/core/assets/icons/svg/${icon_name}.svg`
        );
        ImportedIconRef.current = namedImport;
      } catch (err) {
        ImportedIconRef.current = null;
      }
    };
    importIcon().then(() => {
      setLoading(false);
    });
  }, [icon_name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return (
      <ImportedIcon
        width={size}
        height={size}
        fill={color}
        className={className}
      />
    );
  }

  return null;
}

DisplayIcon.defaultProps = {
  size: 24,
  className: "",
  color: "#000000",
};

DisplayIcon.propTypes = {
  icon_name: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default DisplayIcon;
