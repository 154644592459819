import React from "react";
import BillingDetails from "modules/billing/components/BillingDetails";
import PaymentMethods from "modules/billing/components/PaymentMethods";
import Payments from "modules/billing/components/Payments";
import ContentContainer from "modules/core/layouts/ContentContainer";
import MainContainer from "modules/core/layouts/MainContainer";
import SideBar from "modules/core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";

function ViewBilling() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <BillingDetails />
          <div className="card shadow mt-3">
            <div className="card-body">
              <PaymentMethods />
              <Payments />
            </div>
          </div>
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default ViewBilling;
