import PropTypes from "prop-types";
import React from "react";
/**
 * A container for content that is centered and has a max width.
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {number} props.cols
 */
function ContentContainer(props) {
  const { children, cols, mobile_cols } = props;
  return (
    <div
      className={`container-fluid pt-5 offset-2 col-md-${cols} col-${mobile_cols}`}
    >
      {children}
    </div>
  );
}

ContentContainer.defaultProps = {
  cols: 12,
  mobile_cols: 12,
};

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  cols: PropTypes.number,
  mobile_cols: PropTypes.number,
};

export default ContentContainer;
