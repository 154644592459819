import { Tokens } from "./Models";

export class AuthenticationActions {
  /**
   * Retrieves the authentication tokens from local storage
   * @returns {*}
   */
  retrieveAuthenticationTokens() {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    this.tokens = new Tokens(access_token || "", refresh_token || "");
    return this.tokens;
  }

  /**
   * Stores the authentication tokens in local storage
   * @param tokens
   */
  async storeAuthenticationTokens(tokens: Tokens) {
    await localStorage.setItem("access_token", tokens.access_token);
    await localStorage.setItem("refresh_token", tokens.refresh_token);
    this.tokens = tokens;
  }
}
