import { BaseModel } from "modules/base/models/Base";

/**
 * Focus items model
 */
// eslint-disable-next-line import/prefer-default-export
export class FocusItem extends BaseModel {
  id: number;

  name: string;

  description: string;

  app_path: string;

  script_url: string;

  constructor(data: Object = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.app_path = data.app_path;
    this.script_url = data.script_url;
  }
}
