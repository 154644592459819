import React from "react";
import MainContainer from "../../core/layouts/MainContainer";
import SideBar from "../../core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";

/**
 * Home page
 * @returns {JSX.Element}
 * @constructor
 */
function Home() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <h1>Home</h1>
      </MainContainer>
    </>
  );
}

export default Home;
