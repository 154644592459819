import PropTypes from "prop-types";
import { useCallback } from "react";
import { Icon } from "modules/base/components/Icons";

function ViewAction(props) {
  const { onClick, row, viewButtonColor } = props;

  const handleClick = useCallback(() => {
    onClick(row);
  }, [onClick, row]);
  return (
    <button
      className={`btn btn-outline-${viewButtonColor} btn-sm me-2`}
      type="button"
      onClick={handleClick}
    >
      <Icon icon="bi bi-eye-fill" aria-label="View details" /> View
    </button>
  );
}

ViewAction.defaultProps = {
  onClick: () => {},
  viewButtonColor: "primary",
};

ViewAction.propTypes = {
  onClick: PropTypes.func,
  row: PropTypes.instanceOf(Object).isRequired,
  viewButtonColor: PropTypes.string,
};

export default ViewAction;
