import PropTypes from "prop-types";
import React, { useCallback } from "react";
import AppsApiActions from "modules/apps/ApiActions";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";

function ConfigureDomain(props) {
  const [error, setError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [values, setValues] = React.useState({});
  const { installed_app_id, domain_connection_settings } = props;
  const appsApiActions = new AppsApiActions();
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    appsApiActions.connectDomain(
      installed_app_id,
      values.domain_name,
      () => {
        setIsSubmitting(false);
      },
      (err) => {
        setError(err);
        setIsSubmitting(false);
      },
    );
  });
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setValues((_values) => ({
      ..._values,
      [name]: value,
    }));
  });
  let domainConfigurationForm = (
    <Form
      handleSubmit={handleSubmit}
      button_label="Configure"
      id="configure-domain-form"
      isSubmitting={isSubmitting}
      padding="p-0"
      fullLengthButton
    >
      <div className="row row-cols-1 mb-2">
        <TextInput
          title="Domain name"
          name="domain_name"
          content={values.domain_name}
          controlFunc={handleInputChange}
          required
        />
      </div>
    </Form>
  );
  if ((domain_connection_settings ?? {}).domain_status === "pending") {
    domainConfigurationForm = (
      <div className="row row-cols-1 mb-2">
        <div className="col">
          <NetworkMessageDisplay
            info={domain_connection_settings.domain_status_message}
            additional_classes="text-center"
          />
        </div>
      </div>
    );
  } else if ((domain_connection_settings ?? {}).domain_status === "failed") {
    domainConfigurationForm = (
      <div className="row row-cols-1 mb-2">
        <div className="col">
          <NetworkMessageDisplay
            error={domain_connection_settings.domain_status_message}
            additional_classes="text-center"
          />
        </div>
      </div>
    );
  }
  return (
    <Modal
      modal_title="Configure domain"
      modal_id={`configure-domain-${installed_app_id}`}
      size="md"
    >
      <NetworkMessageDisplay error={error} />
      {domainConfigurationForm}
    </Modal>
  );
}

ConfigureDomain.defaultProps = {
  domain_connection_settings: {},
};

ConfigureDomain.propTypes = {
  installed_app_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  domain_connection_settings: PropTypes.shape({
    domain_name: PropTypes.string,
    domain_status: PropTypes.oneOf(["connected", "pending", "failed"]),
    domain_status_message: PropTypes.string,
  }),
};

export default ConfigureDomain;
