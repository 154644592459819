import { flexRender } from "@tanstack/react-table";
import PropTypes from "prop-types";

function TableRows(props) {
  const { isLoading, data, colSpan, table } = props;
  if (isLoading) {
    return (
      <tr>
        <td colSpan={colSpan}>Loading...</td>
      </tr>
    );
  }
  if (data.length === 0) {
    return (
      <tr>
        <td colSpan={colSpan} className="text-center">
          No data
        </td>
      </tr>
    );
  }
  return table.getRowModel().rows.map((row) => (
    <tr key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} className="text-nowrap text-center text-md-start">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  ));
}

TableRows.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  colSpan: PropTypes.number.isRequired,
  table: PropTypes.instanceOf(Object).isRequired,
};

export default TableRows;
