import PropTypes from "prop-types";
import { useCallback } from "react";

function Button(props) {
  const { label, buttonClasses, disabled, setPage, pageToSet } = props;
  const onClickPage = useCallback((page) => setPage(page), [setPage]);
  const handleClick = useCallback(
    () => onClickPage(pageToSet),
    [[onClickPage, pageToSet]],
  );
  return (
    <button
      type="button"
      className={buttonClasses}
      disabled={disabled}
      onClick={handleClick}
    >
      {label}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  buttonClasses: "border rounded p-1 m-2",
};
Button.propTypes = {
  buttonClasses: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
  pageToSet: PropTypes.number.isRequired,
};

function Pagination(props) {
  const { setPage, pageSize, pageIndex, count } = props;
  const total = Math.ceil(count / pageSize);
  const previousButtonDisabled = pageIndex === 1;
  const nextButtonDisabled = pageIndex === total;

  let startPage = 1;
  let endPage = total;
  if (pageIndex <= 3 || total <= 5) {
    startPage = 1;
    endPage = Math.min(5, total);
  } else if (pageIndex > total - 2) {
    startPage = total - 4;
    endPage = total;
  } else {
    startPage = pageIndex - 2;
    endPage = pageIndex + 2;
  }

  const pageButtons = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => {
      const page = startPage + i;
      const btnClasses = `btn ${
        page === pageIndex ? "btn-purple" : "btn-outline-secondary"
      } m-1`;
      return (
        <Button
          key={page}
          label={page.toString()}
          buttonClasses={btnClasses}
          setPage={setPage}
          pageToSet={page}
        />
      );
    },
  );
  return (
    <div className="flex items-center gap-2">
      <Button
        label="First"
        disabled={previousButtonDisabled}
        setPage={setPage}
        pageToSet={1}
      />
      <Button
        label="Previous"
        disabled={previousButtonDisabled}
        setPage={setPage}
        pageToSet={pageIndex - 1}
      />
      {pageButtons}
      <Button
        label="Next"
        disabled={nextButtonDisabled}
        setPage={setPage}
        pageToSet={pageIndex + 1}
      />
      <Button
        label="Last"
        disabled={nextButtonDisabled}
        setPage={setPage}
        pageToSet={total}
      />
    </div>
  );
}

Pagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default Pagination;
