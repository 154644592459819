import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

function NavigationLink(props) {
  const { path, children, isExternal, classes, isClickable } = props;
  if (!isClickable) {
    return children;
  }
  if (isExternal) {
    return (
      <a
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        className={`text-decoration-none ${classes}`}
      >
        {children}
      </a>
    );
  }
  return (
    <Link to={path} className={`text-decoration-none ${classes}`}>
      {children}
    </Link>
  );
}

NavigationLink.defaultProps = {
  isExternal: false,
  classes: "",
  isClickable: true,
};

NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isExternal: PropTypes.bool,
  classes: PropTypes.string,
  isClickable: PropTypes.bool,
};

export default NavigationLink;
