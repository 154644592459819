import PropTypes from "prop-types";

function NetworkMessageDisplay(props) {
  const { error, success, warning, info, additional_classes } = props;
  if (!error && !success && !warning && !info) {
    return null;
  }
  let message;
  let alert_class;
  let icon;
  if (success) {
    message = success.message || success;
    alert_class = "alert-success";
    icon = "bi bi-check-circle-fill";
  } else if (warning) {
    message = warning.message || warning;
    alert_class = "alert-warning";
    icon = "bi bi-exclamation-circle-fill";
  } else if (info) {
    message = info.message || info;
    alert_class = "alert-info";
    icon = "bi bi-info-circle-fill";
  } else {
    message = error.message || error;
    alert_class = "alert-danger";
    icon = "bi bi-x-circle-fill";
  }
  return (
    <div
      className={`alert ${alert_class} ${additional_classes} text-wrap`}
      role="alert"
    >
      <i className={icon} /> {message}
    </div>
  );
}

NetworkMessageDisplay.defaultProps = {
  error: null,
  success: null,
  warning: null,
  info: null,
  additional_classes: "",
};

NetworkMessageDisplay.propTypes = {
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  success: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  warning: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  info: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  additional_classes: PropTypes.string,
};

export default NetworkMessageDisplay;
